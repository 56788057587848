import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  key = 'worCache';

  private _getCache () : any {
    return JSON.parse(sessionStorage.getItem(this.key) || '{}');
  }

  private _updateCache ( data : any ) : void {
    sessionStorage.setItem(this.key, JSON.stringify(data));
  }

  get ( name : string ) : any {
    const cache = this._getCache();

    return name in cache
      ? cache[ name ]
      : null;
  }

  put ( name : string, data : any ) : void {
    const cache = this._getCache();

    cache[ name ] = data;

    this._updateCache(cache);
  }

  remove ( name : string ) : void {
    const cache = this._getCache();

    delete cache[ name ];

    this._updateCache(cache);
  }

  removeAll () : void {
    sessionStorage.removeItem(this.key);
  }
}
