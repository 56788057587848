import { ApiServiceAjs }       from '../api/api.service.ajs';
import { BigDecimalService }   from '../core/services/big-decimal.service';
import { CodeTypeModel }       from '../core/models/code-type';
import { DateParserService }   from '../core/services/date-parser.service';
import { DomainParserService } from '../core/services/domain.parser.service';
import { MessagesServiceAjs }  from '../messages/messages.service.ajs';
import { RecpatchaService }    from '../recaptcha/recaptcha.service';

export class ReactivateSubscriptionCtrl {
  balance   : string;
  countries : Array<CodeTypeModel>;
  IE        : boolean;
  months    : Array<number>;
  states    : Array<CodeTypeModel>;
  years     : Array<number>;

  backBlock    = this.blockUI.instances.get('pastDueBackBlock');
  failedRetry  = false;
  flipped      = false;
  frontBlock   = this.blockUI.instances.get('pastDueFrontBlock');
  subscription = {} as any;

  constructor (
    private $scope           : ng.IScope,
    private $translate       : ng.translate.ITranslateService,
    private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    private balanceInCents   : number,
    private bigDecimalApi    : BigDecimalService,
    private blockUI          : ng.blockUI.BlockUIService,
    private dateParsing      : DateParserService,
    private domainParserApi  : DomainParserService,
    private messages         : MessagesServiceAjs,
    private recaptchaApi     : RecpatchaService,
    private workshopAPI      : ApiServiceAjs
  ) {
    this.IE = this.domainParserApi.isInternetExplorer();

    this.init();
  }

  enterNewCard () : void {
    this.flip();
  }

  flip () : void {
    this.flipped = !this.flipped;

    this.$scope.$emit('flipModal');

    if ( this.frontBlock.isBlocking() ) {
      this.frontBlock.stop();
    }

    if ( this.backBlock.isBlocking() ) {
      this.backBlock.stop();
    }
  }

  getBalance ( balanceInCents : number ) : string {
    const balance = this.bigDecimalApi.get(balanceInCents);

    return balance.movePointLeft(2).toString();
  }

  getCountries () : void {
    this.workshopAPI.get('/system/country_types', null, 'countries', {
      bypass: true
    })
    .then(countries => {
      this.countries = countries;
    });
  }

  getStates () : void {
    this.workshopAPI.get('/system/states_for_country', this.subscription.billing_country, null, {
      bypass: true
    })
    .then(( states : Array<CodeTypeModel> ) => {
      this.states = states;
    });
  }

  init () : void {
    this.months  = this.dateParsing.getMonths();
    this.years   = this.dateParsing.years();
    this.balance = this.getBalance(this.balanceInCents);

    this.getCountries();
  }

  retryCurrentCard () : void {
    this.frontBlock.start();

    this.recaptchaApi.verifyUser()
    .then(() => this.workshopAPI.post('/chargify/retry_subscription', this.subscription, 'subscription'))
    .then(( result : any ) => {
      if ( result.code == '422' ) {
        this.failedRetry = true;

        this.flip();

        this.messages.show(this.$translate.instant('COMPANY_SPACE.RETRY_FAILED_NEW_CARD'), 'error');
      }
      else {
        this.messages.show(this.$translate.instant('COMPANY_SPACE.RETRY_SUCCESSFUL_LOGGING_IN'), 'success');

        this.$uibModalInstance.close(true);
      }
    })
    .catch(( err : any ) => {
      this.messages.error(err);
    })
    .finally(() => {
      this.frontBlock.stop();
    });
  }

  retryWithNewCard () : void {
    this.backBlock.start();

    this.recaptchaApi.verifyUser()
    .then(() => this.workshopAPI.post('/chargify/update_payment', this.subscription, 'subscription'))
    .then(( subscription : any ) => this.workshopAPI.post('/chargify/retry_subscription', this.subscription, 'subscription'))
    .then(( result : any ) => {
      if ( result.code == '422' ) {
        this.failedRetry = true;

        this.flip();

        this.messages.show(this.$translate.instant('COMPANY_SPACE.NEW_CARD_FAILED'), 'error');
      }
      else {
        this.messages.show(this.$translate.instant('COMPANY_SPACE.RESUBMISSION_SUCCESSFUL'), 'success');

        this.$uibModalInstance.close(true);
      }
    })
    .catch(( err : any ) => {
      this.messages.error(err);
    })
    .finally(() => {
      this.backBlock.stop();
    });
  }
}
