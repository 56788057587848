import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PruneService {
  removeUntouched ( items : Array<any> ) : Array<any> {
    return items.filter(( item : any ) => !item.untouched);
  }
}
