import { EditComponent } from '../../app-lite/table/cell-components/actions/edit.component';

export function VehiclesLiteCtrl (
  $rootScope,
  $scope,
  $state,
  $translate,
  sessionObjAPI,
  tableApi,
  workshopAPI
) {
  $scope.company        = sessionObjAPI.company();
  $scope.fleetCodeLabel = $scope.company.company_variable_fields.fleet_code_name || $translate.instant('VEHICLE_SPACE.FLEET_CODE');
  $scope.tableCacheKey  = 'vehicles';
  $scope.regoLabel      = $scope.company.company_variable_fields.plate_number_name || sessionObjAPI.countrySettings().rego_label;

  $scope.filtering = tableApi.setFiltersFromCache($scope.tableCacheKey, {
    archived_only: false,
    value        : ''
  });

  $scope.tableSettings = {
    broadcast: 'filterVehicles',
    cache    : {
      enabled: true,
      key    : $scope.tableCacheKey
    },
    columns: [
      {
        classes: 'all-caps',
        header : {
          text: $scope.regoLabel
        },
        field: 'plate_number'
      },
      {
        header: {
          text: $translate.instant('VEHICLE_SPACE.MAKE')
        },
        field: 'make'
      },
      {
        header: {
          text: $translate.instant('VEHICLE_SPACE.MODEL')
        },
        field: 'model'
      },
      {
        header: {
          text: $translate.instant('JS_SPACE.COLUMNS.CUSTOMER_NAME')
        },
        field: 'display_name'
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_VEHICLES_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : tableApi.getCachedPaging($scope.tableCacheKey).pageSize || 10,
      pageSizes : [ 10, 25, 50 ],
      queryCount: () => workshopAPI.get('/filters/count', [
        $scope.filtering.value || '*',
        'vehicles',
        $scope.filtering.archived_only
      ])
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: tableApi.setSortingFromCache($scope.tableCacheKey, {
      direction: 'asc',
      field    : 'plate_number'
    })
  };

  $scope.getVehicles = ( params ) => {
    return workshopAPI.stream('/filters', [
      $scope.filtering.value || '*',
      'vehicles',
      params.offset,
      params.pageSize,
      params.field,
      params.direction,
      $scope.filtering.archived_only
    ]).then(vehicles => {
      $scope.vehicles = vehicles;

      return vehicles;
    });
  };

  $scope.init = function () {
    if ($scope.company.company_settings.show_fleet_code) {
      $scope.tableSettings.columns.push({
        field : 'fleet_code',
        header: {
          text: $scope.fleetCodeLabel
        }
      });
    }

    $scope.tableSettings.columns.push({
      component: {
        bindings: {
          outputs: {
            onEditClick: event => $scope.viewVehicle(event),
          }
        },
        enabled: true,
        factory: EditComponent,
      },
      sortable: false,
    });
  };

  $scope.onRowDoubleClick = function (event) {
    $scope.viewVehicle(event.row);
  };

  $scope.viewVehicle = function (vehicle) {
    $state.go('app.vehicle', {
      customer_id: vehicle.customer_id,
      id         : vehicle.id,
    });
  };

  $scope.createBooking = function (vehicle) {
    $state.go('app.event', {
      customer_id: vehicle.customer_id,
      vehicle_id : vehicle.id,
    });
  };

  $scope.createInvoice = function (vehicle) {
    $state.go('app.invoice', {
      customer_id: vehicle.customer_id,
      vehicle_id : vehicle.id,
    });
  };

  $scope.$watch(
    '[filtering.value, filtering.archived_only]',
    (newVal, oldVal) => {
      if (newVal !== oldVal) {
        $rootScope.$broadcast('filterVehicles', $scope.filtering);
      }
    }
  );

  $scope.init();
};