import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'properCase'
})
export class ProperCasePipe implements PipeTransform {
  transform( value : string ) : string {
    return value
      ? value
        .replace(/_/g, ' ') // Replace underscores with spaces
        .toLowerCase()
        .replace(/^(.)|\s(.)/g, (letter) => letter.toUpperCase())
      : value;
  }
}
