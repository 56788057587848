import { ApiModel }                   from '../../app-lite/api/models/api.model';
import { ApiServiceAjs }              from '../../app-lite/api/api.service.ajs';
import { JsonService }                from '../../app-lite/core/services/json.service';
import { QuickbooksSSOSessionModel }  from './models/quickbooks.sso-session';
import { QuickbooksSSOSettingsModel } from './models/quickbooks.sso-settings';
import { SessionServiceAjs }          from '../../app-lite/sessions/session.service.ajs';
import { UserModel }                  from '../../app-lite/users/models/user';

export class QuickbooksServiceAjs {
  $q           : ng.IQService;
  $translate   : ng.translate.ITranslateService;
  API          : ApiModel;
  jsonAPI      : JsonService
  sessionObjAPI: SessionServiceAjs;
  workshopAPI  : ApiServiceAjs;

  constructor (
    $q           : ng.IQService,
    $translate   : ng.translate.ITranslateService,
    API          : ApiModel,
    jsonApi      : JsonService,
    sessionObjAPI: SessionServiceAjs,
    workshopAPI  : ApiServiceAjs
  ) {
    this.$q            = $q;
    this.$translate    = $translate;
    this.API           = API;
    this.jsonAPI       = jsonApi;
    this.sessionObjAPI = sessionObjAPI;
    this.workshopAPI   = workshopAPI;
  }

  authenticate ( settings : QuickbooksSSOSettingsModel ) : ng.IPromise<QuickbooksSSOSessionModel> {
    return this.workshopAPI.post('/quickbooks_online/create_from_signup', settings);
  }

  getAuthenticationUrl () : ng.IPromise<string> {
    return this.workshopAPI.get('/quickbooks_online/authenticate_from_qbo')
    .then(( response : any ) => response.url);
  }

  getSSOSettings ( queryParams : any = this.jsonAPI.getQueryParameters() ) : ng.IPromise<QuickbooksSSOSettingsModel> {
    let settings : QuickbooksSSOSettingsModel = queryParams;

    return this.workshopAPI.post('/quickbooks_online/exchange_code_for_tokens', {
      code: settings.code
    })
    .then(( tokens : QuickbooksSSOSettingsModel ) => {
      settings = angular.extend(settings, tokens);

      return settings;
    });
  }

  getSignupUrlFromCountry ( country : string, queryParams : any = this.jsonAPI.getQueryParameters() ) : ng.IPromise<string> {
    const tag = `/login.html#/quickbooks-signup/${ country }`;

    const queryString = Object.keys(queryParams)
    .map(( key : string ) => `${ key }=${ encodeURIComponent(queryParams[key]) }`)
    .join('&');

    switch (country) {
      case 'can':
        return this.$q.when(`${ this.API.domains.can }${tag}?${queryString}`);
      case 'gbr':
        return this.$q.when(`${ this.API.domains.gbr }${tag}?${queryString}`);
      case 'usa':
        return this.$q.when(`${ this.API.domains.usa }${tag}?${queryString}`);
      default:
        return this.$q.when(`${ this.API.domains.aus }${tag}?${queryString}`);
    }
  }

  getTitle () : string {
    if (this.isDisconnecting()) {
      return 'GENERAL_SPACE.MESSAGE.NOW_DISCONNECTING_YOU';
    }

    if (this.isLoginReady()) {
      return 'GENERAL_SPACE.MESSAGE.LOGGING_YOU_IN';
    }

    return 'GENERAL_SPACE.MESSAGE.NOW_CONNECTING_YOU';
  }

  isDisconnecting ( queryParams : any = this.jsonAPI.getQueryParameters() ) : boolean {
    return queryParams.disconnect === 'true';
  }

  isLoginReady ( queryParams : any = this.jsonAPI.getQueryParameters() ) : boolean {
    return queryParams.qbo_login === 'true' &&
      queryParams.qbo_sso_token;
  }

  launchSSO () : ng.IPromise<string> {
    return this.workshopAPI.get('/quickbooks_online/authenticate', null, null, {
      params: {
        disconnect: this.isDisconnecting()
      }
    })
    .then(response => response.url);
  }

  login ( settings : QuickbooksSSOSessionModel = this.jsonAPI.getQueryParameters() ) : ng.IPromise<string> {
    let session;

    return this.$q.when()
    .then(() => this.workshopAPI.post('/sessions/qbo', settings))
    .then(( response : any ) => {
      session = response;

      return this.sessionObjAPI.setSessionAndValidate(session);
    })
    .then(( rights : any ) => this.isDisconnecting() ? this.workshopAPI.get('/company/remove_qbo/') : this.$q.when(true))
    .then(() => {
      if (this.isDisconnecting()) {
        return this.$q.when('/workshop.html#/quickbooks_settings');
      }
      else if (session.super_user) {
        return this.$q.when('/workshop.html#/admin_dashboard');
      }

      return this.$q.when('/workshop.html#/dashboard');
    });
  }

  needsAuthentication ( queryParams : any = this.jsonAPI.getQueryParameters() ) : boolean {
    return !this.isDisconnecting(queryParams)
      && !queryParams.qbo_sso_token
      && !queryParams.realmId
      && !queryParams.code
      && !queryParams.state;
  }

  signup ( user : UserModel = {}, settings : QuickbooksSSOSettingsModel ) : ng.IPromise<boolean> {
    const params : any = angular.extend(user, settings);

    return this.workshopAPI.post('/subscriptions/quickbooks_setup', params);
  }
}