import { ApiServiceAjs }        from '../../api/api.service.ajs';
import { CodeTypeModel }        from '../../core/models/code-type';
import { CustomerModel }        from '../../customers/models/customer';
import { DomainParserService }  from '../../core/services/domain.parser.service';
import { MessagesServiceAjs }   from '../../messages/messages.service.ajs';
import { MessengerService }     from '../../messages/messenger.service.ajs';
import { Table }                from '../../table/types/table';
import { ValidationsService }   from '../../sessions/validations.service';

export class SendJobBulkSmsCtrl {
  allSelected   : boolean;
  customers     : Array<CustomerModel>;
  flipped       : boolean;
  frontBlock    : ng.blockUI.BlockUIService;
  IE            : boolean;
  selectedItems : Array<CustomerModel>;
  smsMessage    : string;
  tableSettings : Table;

  filters = {
    endDate    : '*',
    invoiceType: '*',
    jobStatus  : '*',
    startDate  : '*'
  };

  constructor (
    private $scope           : ng.IScope,
    private $translate       : ng.translate.ITranslateService,
    private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    private blockUI          : ng.blockUI.BlockUIService,
    public constraints       : Array<CodeTypeModel>,
    private domainParserApi  : DomainParserService,
    private messageSettings  : any,
    private messages         : MessagesServiceAjs,
    private messengerApi     : MessengerService,
    public statusTypes       : Array<CodeTypeModel>,
    private templatedWording : any,
    private validationsApi   : ValidationsService,
    private workshopAPI      : ApiServiceAjs
  ) {
    this.frontBlock = this.blockUI.instances.get('bulkSmsFrontBlock');
    this.IE         = this.domainParserApi.isInternetExplorer();
    this.smsMessage = this.messageSettings.default_bulk_sms_message;

    this.$scope.$watch('$ctrl.grid.selectedItems', ( selectedCustomers : Array<CustomerModel> = [] ) => {
      this.allSelected = selectedCustomers.length && selectedCustomers.length === this.customers.length;
    }, true);
  }

  addTemplates () : void {
    this.templatedWording('sms')
    .then(( templates : Array<any> ) => {
      angular.forEach(templates, template => {
        this.smsMessage = this.smsMessage
          ? `${ this.smsMessage }\n\n${ template.wording }`
          : template.wording;
      });
    });
  }

  onRowClick () : void {
    this.selectedItems = this.customers.filter((customer) => customer.selected);
  }

  search () : void {
    this.frontBlock.start();

    this.workshopAPI.get('/customers/customer_list_for_bulk_sms', [
      this.filters.startDate,
      this.filters.endDate,
      this.filters.invoiceType,
      this.filters.jobStatus
    ])
    .then(( customers : Array<CustomerModel> = []) => {
      this.customers = customers;

      if (this.customers.length) {
        this.tableSettings = {
          columns: [
            {
              field : 'display_name',
              header: {
                text: this.$translate.instant('CUSTOMER_SPACE.CUSTOMER')
              }
            },
            {
              field : 'mobile',
              header: {
                text: this.$translate.instant('GENERAL_SPACE.FIELD.MOBILE')
              }
            },
            {
              field : 'job_card_number',
              header: {
                text: this.$translate.instant('INVOICE_SPACE.JOB_CARD_NUMBER')
              }
            },
            {
              field : 'job_status_comment',
              header: {
                text: this.$translate.instant('INVOICE_SPACE.JOB_STATUS_COMMENT')
              }
            }
          ],
          empty: {
            text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND')
          },
          pagination: {
            enabled: false
          },
          rows: {
            highlight: {
              enabled: true
            },
            select: {
              enabled: true,
              multi  : true
            }
          },
          sorting: {
            enabled: false
          },
          static: true
        };

        this.selectedItems = [];

        this.frontBlock.start();

        this.flipped = !this.flipped;

        this.$scope.$emit('flipModal');
      }
      else {
        this.messages.show(this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND'), 'error', {});

        this.$uibModalInstance.close();
      }
    })
    .finally(() => {
      this.frontBlock.stop();
    });
  }

  selectAll () : void {
    this.allSelected = !this.allSelected;

    angular.forEach(this.customers, ( invoice : any ) => {
      invoice.selected = this.allSelected;
    });

    this.selectedItems = this.customers.filter((invoice) => invoice.selected);
  }

  sendSms () : void {
    const customers = [];

    angular.forEach(this.selectedItems, ( customer : CustomerModel ) => {
      const isMobileValid = this.validationsApi.isPhoneNumberValid(customer.mobile, true);
      const firstName     = customer.customer_type === 'C'
        ? ( customer.contact_first || customer.contact1_first || customer.contact2_first || customer.company_name )
        : customer.first_name;

      customers.push({
        first_name: firstName,
        name      : customer.display_name,
        phone     : customer.mobile,
        valid     : isMobileValid ? 'yes' : 'no'
      });
    });

    this.messengerApi.sendBulkSms(customers, this.smsMessage)
    .then(() => {
      this.messages.show(this.$translate.instant('GENERAL_SPACE.MESSAGE.BULK_SMS_SENT'), 'success', {});

      this.$uibModalInstance.close();
    })
    .catch(( err : any ) => {
      this.messages.error(err);
    });
  }
}
