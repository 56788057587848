import { Injectable } from '@angular/core';

import { BigDecimalService } from './big-decimal.service';
import { CurrencyModel }     from '../models/currency';
import { SessionServiceAjs } from '../../sessions/session.service.ajs';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  constructor (
    private bigDecimalService: BigDecimalService,
    private sessionService   : SessionServiceAjs
  ) {}

  getInfo () : CurrencyModel {
    const session = this.sessionService.base();

    return {
      price_includes_tax: session.company_settings.price_includes_tax,
      purchases_tax_rate: session.company_settings.purchases_tax_rate,
      round_total       : session.company_settings.round_total,
      sales_tax_rate    : session.company_settings.sales_tax_rate,
      symbol            : session.country_settings.currency,
      tax_name          : session.company_settings.tax_name || 'GST'
    };
  }

  getItemGst ( item : any, taxFree : boolean, editable : boolean ) : string {
    const info = this.getInfo();

    item.quantity = item.quantity && item.quantity !== '-'
      ? item.quantity
      : '0';

    const quantity  = this.bigDecimalService.get(item.quantity, 2);
    const taxRate   = this.bigDecimalService.get(info.sales_tax_rate, 2);
    const unitPrice = this.bigDecimalService.get(item.unit_price, 2);

    const total = unitPrice.multiply(quantity);

    return !editable
      ? item.gst || this.bigDecimalService.get().toString()
      : item.gst_free || taxFree
        ? this.bigDecimalService.get().toString()
        : info.price_includes_tax
          ? total
            .subtract(total.divide(BigDecimal.ONE.add(taxRate.movePointLeft(2))))
            .setScale(2, BigDecimal.prototype.ROUND_HALF_UP)
            .toString()
          : total
            .multiply(taxRate.movePointLeft(2))
            .setScale(2, BigDecimal.prototype.ROUND_HALF_UP)
            .toString();
  }

  getItemTotal ( item : any, taxFree : boolean, editable : boolean ) : string {
    const info = this.getInfo();

    item.quantity = item.quantity && item.quantity !== '-'
      ? item.quantity
      : '0';

    const quantity  = this.bigDecimalService.get(item.quantity, 2);
    const taxRate   = this.bigDecimalService.get(info.sales_tax_rate, 2);
    const unitPrice = this.bigDecimalService.get(item.unit_price, 2);

    const total = unitPrice.multiply(quantity);

    return !editable
      ? item.amount || this.bigDecimalService.get().toString()
      : item.gst_free || info.price_includes_tax || taxFree
        ? total.toString()
        : total
          .multiply(taxRate.movePointLeft(2))
          .setScale(2, BigDecimal.prototype.ROUND_HALF_UP)
          .add(total)
          .setScale(2, BigDecimal.prototype.ROUND_HALF_UP)
          .toString();
  }

  getSubtotal ( data : any, items : any, closed : boolean ) : string {
    const info = this.getInfo();

    let gst      = this.bigDecimalService.get();
    let subtotal = this.bigDecimalService.get();
    let total    = this.bigDecimalService.get();

    if ( closed ) {
      return parseFloat(data.subtotal).toString() || '0';
    }

    if ( info.price_includes_tax && !data.tax_free ) {
      items.forEach(item => {
        const gstCalc = data.tax_free
          ? this.bigDecimalService.get()
          : this.bigDecimalService.get(item.getGST().toString());

        const itemTotal = this.bigDecimalService.get(item.getTotal().toString());

        gst   = gst.add(gstCalc);
        total = total.add(itemTotal);
      });

      subtotal = total.subtract(gst);
    } else {
      items.forEach(item => {
        item.quantity = item.quantity && item.quantity !== '-'
          ? item.quantity
          : '0';

        const quantity  = this.bigDecimalService.get(item.quantity);
        const unitPrice = this.bigDecimalService.get(item.unit_price);

        subtotal = subtotal.add(unitPrice.multiply(quantity));
      });
    }

    return subtotal
      ? subtotal.toString()
      : '0';
  }

  getTotal ( data : any, items : any, closed : boolean ) : string {
    const info = this.getInfo();

    const freight = this.bigDecimalService.get(data.freight, 2);
    const gst     = data.tax_free
      ? this.bigDecimalService.get()
      : this.bigDecimalService.get(this.getTotalGst(data, items, closed).toString(), 2);

    const total = this.bigDecimalService.get(this.getSubtotal(data, items, closed).toString(), 2);

    return closed
      ? parseFloat(data.total).toString() || '0'
      : info.round_total
        ? this.bigDecimalService.round(total.add(gst).add(freight).toString())
        : total.add(gst).add(freight).toString();
  }

  getTotalBalance ( data : any, items : any, closed : boolean ) : string {
    const total = this.bigDecimalService.get(this.getTotal(data, items, closed).toString(), 2);

    return closed
      ? data.balance_due || '0'
      : total.toString();
  }

  getTotalGst ( data : any, items : any, closed: boolean ) : string {
    const company = this.sessionService.company();
    const info    = this.getInfo();

    let gst      = this.bigDecimalService.get();
    var subtotal = this.bigDecimalService.get();

    const freight = company.tax_freight
      ? this.bigDecimalService.get(data.freight)
      : this.bigDecimalService.get();

    const taxRate = this.bigDecimalService.get(company.sales_tax_rate).movePointLeft(2);

    if ( closed ) {
      return data.gst;
    } else if ( data.tax_free ) {
      return gst.toString();
    }

    items.forEach(item => {
      let quantity  = this.bigDecimalService.get();
      let unitPrice = this.bigDecimalService.get();

      if ( !item.gst_free ) {
        item.quantity = item.quantity && item.quantity !== '-'
          ? item.quantity
          : '0';

        quantity  = this.bigDecimalService.get(item.quantity, 2);
        unitPrice = this.bigDecimalService.get(item.unit_price, 2);

        subtotal = subtotal.add(unitPrice.multiply(quantity));
      }
    });

    gst = info.price_includes_tax
      ? subtotal
        .subtract(subtotal.divide(BigDecimal.ONE.add(taxRate)))
        .setScale(2, BigDecimal.prototype.ROUND_HALF_UP)
      : subtotal
        .multiply(taxRate)
        .setScale(2, BigDecimal.prototype.ROUND_HALF_UP);

    return gst.add(freight.multiply(taxRate)
        .setScale(2, BigDecimal.prototype.ROUND_HALF_UP))
      .toString();
  }

  getTotalRounding ( data : any, items : any ) : string {
    const info = this.getInfo();

    if ( !info.round_total ) {
      return '0.00';
    }
    else if ( closed ) {
      return data.rounding;
    }
    else {
      const freight = this.bigDecimalService.get(data.freight, 2);
      const gst     = data.tax_free
        ? this.bigDecimalService.get()
        : this.bigDecimalService.get(this.getTotalGst(data, items, closed).toString(), 2);

      const subtotal = this.bigDecimalService.get(this.getSubtotal(data, items, closed).toString());

      const roundedTotal = this.bigDecimalService.get(this.bigDecimalService.round(subtotal.add(gst).add(freight).toString()));

      return (subtotal.add(gst).add(freight))
        .subtract(roundedTotal)
        .negate()
        .toString();
    }
  }
}
