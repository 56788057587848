import { CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule }               from '@angular/core';

import { ArchivedPipe }           from './archived.pipe';
import { CapitalizePipe }         from './capitalize.pipe';
import { ChargifyCurrencyPipe }   from './chargify-currency.pipe';
import { CutPipe }                from './cut.pipe';
import { DateTimePipe }           from './date-time.pipe';
import { GlobalCurrencyPipe }     from './global-currency.pipe';
import { GlobalDatePipe }         from './global-date.pipe';
import { HoursPipe }              from './hours.pipe';
import { HtmlToPlainTextPipe }    from './html-to-plain-text.pipe';
import { MobilePhonePipe }        from './mobile.pipe';
import { MonthPipe }              from './month.pipe';
import { MonthsPipe }             from './months.pipe';
import { NumberPipe }             from './number.pipe';
import { PhonePipe }              from './phone.pipe';
import { PluralizePipe }          from './pluralize.pipe';
import { ProperCasePipe }         from './proper-case.pipe';
import { RegexPipe }              from './regex.pipe';
import { TaxTypePipe }            from './tax-type.pipe';

@NgModule({
  declarations: [
    ArchivedPipe,
    CapitalizePipe,
    ChargifyCurrencyPipe,
    CutPipe,
    DateTimePipe,
    GlobalCurrencyPipe,
    GlobalDatePipe,
    HoursPipe,
    HtmlToPlainTextPipe,
    MobilePhonePipe,
    MonthPipe,
    MonthsPipe,
    NumberPipe,
    PhonePipe,
    PluralizePipe,
    ProperCasePipe,
    RegexPipe,
    TaxTypePipe
  ],
  exports: [
    // Custom pipes
    ArchivedPipe,
    CapitalizePipe,
    ChargifyCurrencyPipe,
    CutPipe,
    DateTimePipe,
    GlobalCurrencyPipe,
    GlobalDatePipe,
    HoursPipe,
    HtmlToPlainTextPipe,
    MobilePhonePipe,
    MonthPipe,
    MonthsPipe,
    NumberPipe,
    PhonePipe,
    PluralizePipe,
    ProperCasePipe,
    RegexPipe,
    TaxTypePipe
  ],
  providers: [
    // Angular pipes
    CurrencyPipe,
    DatePipe,

    // Custom pipes
    ArchivedPipe,
    CapitalizePipe,
    ChargifyCurrencyPipe,
    CutPipe,
    DateTimePipe,
    GlobalCurrencyPipe,
    GlobalDatePipe,
    HoursPipe,
    HtmlToPlainTextPipe,
    MobilePhonePipe,
    MonthPipe,
    MonthsPipe,
    NumberPipe,
    PhonePipe,
    PluralizePipe,
    ProperCasePipe,
    RegexPipe,
    TaxTypePipe,

    // Pipes must also be provided via string
    // if they are going to be used in a table
    // column. This allows the injector to be
    // able to load a pipe dynamically in the
    // TableCellComponent.
    {
      provide : 'archived',
      useClass: ArchivedPipe
    },
    {
      provide : 'capitalize',
      useClass: CapitalizePipe
    },
    {
      provide : 'chargifyCurrency',
      useClass: ChargifyCurrencyPipe
    },
    {
      provide : 'cut',
      useClass: CutPipe
    },
    {
      provide : 'date',
      useClass: DatePipe
    },
    {
      provide : 'dateTime',
      useClass: DateTimePipe
    },
    {
      provide : 'globalCurrency',
      useClass: GlobalCurrencyPipe
    },
    {
      provide : 'globalDate',
      useClass: GlobalDatePipe
    },
    {
      provide : 'hours',
      useClass: HoursPipe
    },
    {
      provide : 'htmlToPlainText',
      useClass: HtmlToPlainTextPipe
    },
    {
      provide : 'mobilePhone',
      useClass: MobilePhonePipe
    },
    {
      provide : 'month',
      useClass: MonthPipe
    },
    {
      provide : 'months',
      useClass: MonthsPipe
    },
    {
      provide : 'number',
      useClass: NumberPipe
    },
    {
      provide : 'phone',
      useClass: PhonePipe
    },
    {
      provide : 'pluralize',
      useClass: PluralizePipe
    },
    {
      provide : 'properCase',
      useClass: ProperCasePipe
    },
    {
      provide : 'regex',
      useClass: RegexPipe
    },
    {
      provide : 'taxType',
      useClass: TaxTypePipe
    }
  ]
})
export class PipesModule {}