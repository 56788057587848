import { Injector, NgModule } from '@angular/core';

import { SessionServiceAjs }  from './session.service.ajs';
import { SharedModule }       from '../shared/shared.module';
import { ValidationsService } from './validations.service';

@NgModule({
  imports: [
    SharedModule
  ],
  providers: [
    ValidationsService,

    // Injected AngularJS services
    {
      deps      : [ '$injector' ],
      provide   : SessionServiceAjs,
      useFactory: ( $injector : Injector ) => $injector.get('sessionObjAPI')
    }
  ]
})
export class SessionsModule {}
