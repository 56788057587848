import AshdownProductSearchResultsHtml from './templates/ashdown-products.search-results.html';
import ProductsAdvancedSearchHtml      from './products.advanced-search.html';
import ProlinkProductSearchResultsHtml from './templates/prolink-products.search-results.html';
import RepcoProductSearchResultsHtml   from './templates/repco-products.search-results.html';
import WindowFlipHtml                  from '../core/templates/window-flip.html';

export function ProductSearchLiteService (
  $uibModal,
  $q,
  $translate,
  messages,
  streamSearch,
  workshopAPI
) {
  // internal tracking of event stream
  let _stream = undefined;

  // Flag to block quick search (when stream has already resolved)
  let _blockQuickSearch = false;

  const ProductSearch = function () {
    this.$uibModal     = $uibModal;
    this.$q         = $q;
    this.$translate = $translate;

    this.messages     = messages;
    this.streamSearch = streamSearch;
    this.workshopAPI  = workshopAPI;

    this.products = [];
  };

  ProductSearch.prototype.advancedSearch = function (product) {
    const self = this;

    return self.$uibModal.open({
      controller       : 'ProductsAdvancedSearchLiteCtrl',
      size             : 'lg',
      templateUrl      : ProductsAdvancedSearchHtml,
      windowClass      : 'product-search-modal flip-container',
      windowTemplateUrl: WindowFlipHtml,
      resolve          : {
        groups : () => self.workshopAPI.get('/product_groups'),
        product: () => product,
        types  : () => self.workshopAPI.get('/system/product_types/', null, 'productTypes')
      }
    }).result;
  };

  ProductSearch.prototype.deleteItems = function (items, embed) {
    const self = this;

    return self.workshopAPI.post(self.routes.delete, items, embed);
  };

  ProductSearch.prototype.getAdjustedCost = function(customer, item) {
    const self = this;

    return self.workshopAPI
      .get(self.routes.adjustedCost, [ customer.id, item.id ])
      .catch(() => item.retail_price);
  };

  ProductSearch.prototype.getBomItems = function (product, params) {
    const self = this;

    if ((params.length <= 3 && params[1]) || params.length > 3) {
      return self.workshopAPI.get(self.routes.bomItems, params || product.id);
    }
    else {
      self.messages.show($translate.instant('CUSTOMER_SPACE.NO_CUSTOMER_ATTACHED_BUNDLE'), 'error');

      return self.$q.reject();
    }
  };

  ProductSearch.prototype.getLaborProducts = function ( value ) {
    const self = this;

    return self.workshopAPI.get('/products/product_labor_list', [ value, 0, 100 ]);
  };

  ProductSearch.prototype.getSearchTemplate = function (ashdown, repco, prolink) {
    if (ashdown.vendor) {
      return AshdownProductSearchResultsHtml;
    }
    else if (repco.vendor) {
      return RepcoProductSearchResultsHtml;
    }
    else if (prolink.vendor) {
      return ProlinkProductSearchResultsHtml;
    }
    else {
      return null;
    }
  };

  /**
   * When the user tabs out of the field, we
   * want to do a quick lookup on the typed value.
   * If we get a matching product, select it and
   * cancel the stream
   */
  ProductSearch.prototype.quickSearch = function (typed) {
    const self = this;

    // Cancel if quick search is blocked or no value is typed
    if (_blockQuickSearch || !typed) {
      _blockQuickSearch = false;

      return self.$q.reject();
    }

    return self.workshopAPI
      .get('/product/find_by_item_code', typed)
      .then(product => {
        // Since quick search is resolved now, cancel any stream
        if (_stream) {
          _stream.close();
          _stream = undefined;
        }

        return product;
      });
  };

  /**
   * Performs an eventsource stream with
   * the typed value. Different to above as it gets the qty available
   */
  ProductSearch.prototype.reservedStockSearch = function (typed) {
    const self = this;

    // Quick Search is available until this call resolves
    _blockQuickSearch = false;

    var search = self.streamSearch.search('products_reserved', typed, { verbose: true });

    // Save the stream for reference
    _stream = search.stream;

    return search.promise.then(products => {
      products.push({
        item_code: typed,
        last     : true
      });

      return self.products.concat(products);
    }).finally(() => {
      // Now that it has resolved, block any quick searches
      _blockQuickSearch = true;
    });
  };

  ProductSearch.prototype.search = function (typed) {
    const self = this;

    // Quick Search is available until this call resolves
    _blockQuickSearch = false;

    var search = self.streamSearch.search('products', typed, { verbose: true });

    // Save the stream for reference
    _stream = search.stream;

    return search.promise.then(products => {
      products.push({
        item_code: typed,
        last     : true
      });

      return self.products.concat(products);
    }).finally(() => {
      // Now that it has resolved, block any quick searches
      _blockQuickSearch = true;
    });
  };

  ProductSearch.prototype.searchBranchProducts = function (company_id, typed) {
    const self = this;

    return self.workshopAPI.post('/product/branch_find', {
      company_id: company_id,
      item_code : typed
    });
  };

  ProductSearch.prototype.searchDealerBranchProducts = function(company_id, typed) {
    const self = this;

    return self.workshopAPI.post('/product/branch_find_on_dealer', {
      company_id: company_id,
      item_code : typed
    });
  };

  ProductSearch.prototype.updatePricing = function (items, embed) {
    const self = this;

    return workshopAPI.post(self.routes.updatePricing, items, embed);
  };

  return ProductSearch;
};