import { Component, Inject, Input, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI }               from 'ng-block-ui';
import { NgbActiveModal }                   from '@ng-bootstrap/ng-bootstrap';
import { NgForm }                           from '@angular/forms';

import { InvoiceModel }       from './models/invoice';
import { MessagesServiceAjs } from '../messages/messages.service.ajs';
import { MessengerService }   from '../messages/messenger.service.ajs';

@Component({
  selector   : 'invoice-sms',
  template: require('./invoice.sms.component.html')
})
export class InvoiceSmsComponent implements OnInit {
  @BlockUI('block') block : NgBlockUI;

  @Input() invoice: InvoiceModel;
  @Input() sms    : any;

  invoiceApi: any;

  constructor(
    @Inject('$translate')
    public  $translate       : ng.translate.ITranslateService,
    public  activeModal      : NgbActiveModal,
    @Inject('Invoice')
    private Invoice          : any,
    private messagesService  : MessagesServiceAjs,
    private messengerService : MessengerService,
    @Inject('templatedWording')
    private templatedWording : any
  ) {}

  ngOnInit () : void {
    this.invoiceApi = new this.Invoice(this.invoice);
  }

  addTemplates () : void {
    this.templatedWording('sms')
    .then(templates => {
      templates.forEach(template => {
        this.sms.message = this.sms.message
          ? `${ this.sms.message }\n\n${ template.wording }`
          : template.wording;
      });
    });
  }

  isPhoneNumberValid ( form : NgForm ) : boolean {
    return !form.form.controls.send_to?.errors?.phoneValidator;
  }

  send ( form : NgForm ) : void {
    this.block.start();

    this.messengerService.sendSms(this.sms)
    .then(() => this.invoiceApi.save())
    .then(() => {
      this.activeModal.close();
    })
    .catch(err => {
      this.messagesService.error(err);
    })
    .finally(() => {
      this.block.stop();
    });
  }
}
