import { downgradeInjectable } from '@angular/upgrade/static';
import 'angular';

import 'angular-cookies';

import '../core/core.module.ajs';

import { SessionServiceAjs }  from './session.service.ajs';
import { ValidationsService } from './validations.service';

angular.module('app-lite.sessions', [
  'ngCookies',

  'app-lite.core'
]).service('sessionObjAPI', SessionServiceAjs)
  .service('validationsApi', downgradeInjectable(ValidationsService));
