import { Injectable } from '@angular/core';
import { UrlService } from '@uirouter/core';

/**
 * handles all json manipulation like deep copying and parsing.
 * Some built in methods are not the most efficient way so these
 * methods offer alternatives.
 */
@Injectable({
  providedIn: 'root'
})
export class JsonService {
  constructor(
    private urlService: UrlService
  ) {}

  // Creates a copy of an object
  copy ( obj = {} ) : any {
    return JSON.parse(JSON.stringify(obj));
  }

  // takes all the query parameters and parses them into a json object
  getQueryParameters () : any {
    const params      = new URLSearchParams(parent.window.location.search);
    const queryParams = this.urlService.search();

    const paramObj = {};

    for ( const key of params.keys() ) {
      paramObj[ key ] = decodeURIComponent(params.get(key));
    }

    for ( const key in queryParams ) {
      paramObj[ key ] = decodeURIComponent(queryParams[key]);
    }

    return this.copy(paramObj);
  }

  stripEmpties ( items : Array<any>, join ?: string ) : Array<any> | string {
    const strippedItems = items.filter(Boolean);

    return join
      ? strippedItems.join(join)
      : strippedItems;
  }
}