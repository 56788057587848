import 'angular';

import '../core/core.module.ajs';

import { downgradeInjectable } from '@angular/upgrade/static';

import { API }                 from './api.constant.ajs';
import { ApiServiceAjs }       from './api.service.ajs';
import { CacheService }        from './cache.service';
import { HostConfig }          from './api.host-config.ajs';
import { STATUSCODES }         from './api.status-codes.constant.ajs';
import { StreamSearchService } from './stream-search.service';

angular.module('app-lite.api', [
  'app-lite.core'
]).value('API', API)
  .config(HostConfig)
  .constant('STATUSCODES', STATUSCODES)
  .service('cacheApi', downgradeInjectable(CacheService))
  .service('streamSearch', downgradeInjectable(StreamSearchService))
  .service('workshopAPI', ApiServiceAjs);
