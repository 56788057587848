import { Injector, NgModule } from '@angular/core';

import { BigDecimalService }    from './big-decimal.service';
import { BlockUIService }       from './block-ui.service';
import { CurrencyService }      from './currency.service';
import { DateParserService }    from './date-parser.service';
import { DomainParserService }  from './domain.parser.service';
import { JsonService }          from './json.service';
import { PruneService }         from './prune.service';
import { WindowService }        from './window.service';

@NgModule({
  providers: [
    BigDecimalService,
    BlockUIService,
    CurrencyService,
    DateParserService,
    DomainParserService,
    JsonService,
    PruneService,
    WindowService,

    {
      deps      : [ '$injector' ],
      provide   : 'blockUI',
      useFactory: ( $injector : Injector ) => $injector.get('blockUI')
    }
  ]
})
export class ServicesModule {}
