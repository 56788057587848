import { DatePipe }            from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { DateParserService } from '../../core/services/date-parser.service';

@Pipe({
  name: 'globalDate'
})
export class GlobalDatePipe implements PipeTransform {

  constructor(
    private datePipe         : DatePipe,
    private dateParserService: DateParserService
  ) {}

  transform ( date : string, format : string ) : string {
    format = format || this.dateParserService.getFormat(true);

    try {
      return this.datePipe.transform(date, format);
    } catch (error) {
      console.log('invalid date: ', date);

      return '';
    }
  }
}
