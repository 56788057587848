import angular from 'angular';

import { XeroCountryCtrl }     from './xero.country.ajs';
import { XeroSignInComponent } from './xero.sign-in.component.ajs';
import { XeroSignInCtrl }      from './xero.sign-in.ajs';
import { XeroSignupCtrl }      from './xero.signup.ajs';
import { XeroStates }          from './xero.states.ajs';

angular.module('app.xero', [])
  .component('worXeroSignIn', XeroSignInComponent)
  .config(XeroStates)
  .controller('XeroCountryCtrl', XeroCountryCtrl)
  .controller('XeroSignInCtrl', XeroSignInCtrl)
  .controller('XeroSignupCtrl', XeroSignupCtrl);
