import CreateCarLoanHtml from './loan-car-schedule.create.html';
import ReturnLoanCarHtml from './templates/return.loan.car.html';
import ReviewLoanCarHtml from './templates/review.loan.car.html';
import StartLoanCarHtml  from './templates/start.loan.car.html';
import WindowFlipHtml    from '../core/templates/window-flip.html';

export function LoanCarSchedule (
  $q,
  $rootScope,
  $state,
  $stateParams,
  $translate,
  $uibModal,
  dateParsing,
  LoanCar,
  messages,
  workshopAPI
) {

  var LoanCarSchedule = function (settings, loanVehicleGroups) {
    var self = this;

    //this.colors              = [];
    this.dayOfMonth          = 0;
    this.loans              = [];
    this.loanVehicleGroupIndex  = $stateParams.group_index || 0;
    this.loanVehicleGroups      = [];
    this.shopCloses          = settings.shop_closes || '18:00:00';
    this.shopOpens           = settings.shop_opens || '08:00:00';
    //this.showAllShopForMonth = settings.show_all_shop_for_month;
    this.tab                 = angular.fromJson(localStorage.getItem('loanCarTab')) || 'allocated';
    this.view                = {};

    // if the shop doesn't close until after midnight, force the
    // calendar to stop at 11:59:59 PM.
    if (moment(this.shopCloses, 'hh:mm:ssZ').isBefore(moment(this.shopOpens, 'hh:mm:ssZ'))) {
      this.shopCloses = '23:59:59' + moment(this.shopCloses, 'hh:mm:ssZ').format('Z');
    }

    // methods used to configure event data retrieved
    // for each of the booking diary views (month/week/day).
    this.loanConfiguration = {
      day: configureDayLoanData
    };

    // routes used to retrieve event data for each of
    // the booking diary views (month/week/day).
    this.loanRoutes = {
      day: '/loan_cars/loan_car_events_for_day'
    };

    this.tabs = [
      {
        active : self.tab === 'allocated',
        heading: $translate.instant('JS_SPACE.TABS.CALENDAR'),
        id     : 'allocated',
        onClick: function () {
          localStorage.setItem('loanCarTab', angular.toJson('allocated'));
          $state.reload();
        }
      },
      {
        active : self.tab === 'simplified',
        heading: $translate.instant('JS_SPACE.TABS.LIST'),
        id     : 'simplified',
        onClick: function () {
          localStorage.setItem('loanCarTab', angular.toJson('simplified'));
          $state.reload();
        }
      }
    ];

    if (self.tab === 'allocated') {
      writeClass('simplifiedDayView', '.simplified-day-view { display: none; }');
      writeClass('fcViewContainer', 'fc-view-container { display: inherit; }');
    }
    else {
      writeClass('fcViewContainer', '.fc-view-container { display: none; }');
      writeClass('simplifiedDayView', 'simplified-day-view { display: inherit; }');
    }

    // remove unnecessary key/value pair in returned group data
    for (var i=0; i<loanVehicleGroups.length; i++) {
      if (typeof loanVehicleGroups[i][i] !== 'undefined') {
        loanVehicleGroups[i] = loanVehicleGroups[i][i];
      };

    }

    // configure this.mechanicGroups
    angular.forEach(loanVehicleGroups, function (loanVehicleGroup) {
      var group = { headerColumns: [], ids: [], plate_numbers: [], models: [], colurs: [] };

      group.group_name = loanVehicleGroup.group_name;

      angular.forEach(loanVehicleGroup.loan_vehicle_ids, function (loan_vehicle) {

        angular.forEach(loan_vehicle, function (plate_number, id) {
          var column_name = plate_number[0] + " " + plate_number[2] + " " + plate_number[1];

          group.ids.push(id);
          group.plate_numbers.push(plate_number[0]);
          group.headerColumns.push(
            `<a class="pointer" ui-sref="app.loan-car({
              view: 'multiColAgendaDay',
              group_index: 0,
              date: ${$stateParams.date},
              loanVehicle: ${encodeURI(plate_number)},
              loanVehicleId: ${id},
              viewSingleLoanVehicle: true
            })">${column_name}</a>`
          );
        });
      });

      self.loanVehicleGroups.push(group);
    });

    // if url contains mechanic, user clicked a mechanic
    // from month view or day view. If they clicked from
    // month view, we change to day view and find the group
    // containing the mechanic. If they clicked from day view,
    // we build a new group containing only that mechanic.
    if ($stateParams.loanVehicle) {
      var loanVehicle = $stateParams.loanVehicle;
      var loanVehicleId = $stateParams.loanVehicleId;

      if ($stateParams.viewSingleLoanVehicle) {
        this.loanVehicleGroupIndex = 0;
        this.loanVehicleGroups.unshift({
          group_name   : loanVehicle,
          ids          : [ loanVehicleId ],
          plate_numbers: [ loanVehicle ]
        });
      }
      else {
        for (var i=0; i<this.loanVehicleGroups.length; i++) {

          for (var j=0; j<this.loanVehicleGroups[i].ids.length; j++) {
            if (this.loanVehicleGroups[i].ids[j] === $stateParams.loanVehicleId) {
              this.loanVehicleGroupIndex = i;
            }
          }
        }
      }
    }

    this.loanVehicleGroup = this.loanVehicleGroups.length ? this.loanVehicleGroups[this.loanVehicleGroupIndex]: { headerColumns: [], ids: [], plate_numbers: [], group_name: 'N/A' };
  };

  //  writeClass    used to dynamically write or replace
  //                a class in the head element.
  //  params: id    'String' used for element id.
  //          css   'String' defining css to write.
  var writeClass = function (id, css) {
    var documentHead = document.getElementsByTagName('head')[0];
    var oldStyle = document.getElementById(id);
    var style = document.createElement('style');

    style.id = id;
    style.type = 'text/css';
    style.innerHTML = css;

    // replace old class if it exists.
    oldStyle ? documentHead.replaceChild(style, oldStyle) : documentHead.appendChild(style);
  };

  //  configureDayEventData  used to configure returned
  //                         event data for ui-calendar
  //                         day view.
  //  params: events         [Array] array of events.
  var configureDayLoanData = function (loans) {
    var configuredLoans = [];

    // loop through events object to get date
    angular.forEach(loans, function (loan_vehicles, date) {

      // loop through each loan vehicle for the day
      angular.forEach(loan_vehicles, function (loan_vehicle, loanVehicleId) {

        // if loan vehicle is an array, it's empty.
        if (!angular.isArray(loan_vehicle)) {

          // loop through each event for the loan vehicle
          // and configure the event.
          angular.forEach(loan_vehicle.loan_times, function (loanTime) {
            loanTime.title = loan_vehicle.plate_number;
            loanTime.allDay = false;
            loanTime.color = '#FF0000';
            loanTime.start = moment(loanTime.start_time).format('YYYY-MM-DDTHH:mm:ss');
            loanTime.end = moment(loanTime.end_time).format('YYYY-MM-DDTHH:mm:ss');

            configuredLoans.push(loanTime);
          });
        }
      });
    });

    return configuredLoans;
  };

  //  getLoanOffsetInterval used to determine the offset from
  //                         left to right for each loan car
  //                         event.
  //  params: view           'String' current view interval unit.
  //          vehicles      [Array] array of current loan car vehicles.
  var getLoanOffsetInterval = function (view, loanVehicles) {
    return view === 'week' ? (100/loanVehicles.length) : 0;
  };

  //  getLoanWidth      used to determine the width
  //                     of each loan vehicle event.
  //  params: view       'String' current view interval unit.
  //          loanVehicles  [Array] array of current loan cars vehicles.
  var getLoanWidth = function (view, loanVehicles) {
    return view === 'week' ? ((100/loanVehicles.length)/2) : 100;
  };

  //  getInitialLoanOFfset  used to determine the offset
  //                         from left to right of the
  //                         initial mechanic event.
  //  params: view           'String' current view interval unit.
  //          loanVehicles      [Array] array of current loan vehicles.
  var getInitialLoanOffset = function (view, loanVehicles) {
    return view === 'week' ? (100/loanVehicles.length)*0.225 : 1;
  };

  //  assignMechanics  used to build array of unique mechanic
      //                   names that are assigned to an event.
      //  params: events   [Array] array of events.
  LoanCarSchedule.prototype.assignLoanVehicles = function (loans) {
    var self = this;

    self.assignedLoanVehicles = [];

    // build array of assigned mechanics.
    angular.forEach(loans, function (loan) {
      if (self.assignedLoanVehicles.indexOf(loan.title) < 0) {
        self.assignedLoanVehicles.push(loan.title);
      }
    });
  };

  LoanCarSchedule.prototype.goToLoan = function (id, type) {
    var self = this;

    workshopAPI.get('/loan_car', id).then(function(result){
      self.LoanCar = new LoanCar(result);
      self.LoanCar.configureUpdate(result);
    }).then(function() {

      if (type == 'startLoan') {
        return $uibModal.open({
          controller       : 'StartLoanCarCtrl',
          templateUrl      : StartLoanCarHtml,
          windowClass      : 'x-lg-modal flip-container',
          windowTemplateUrl: WindowFlipHtml,
          resolve          : {
            company        : () => workshopAPI.get('/company', self.LoanCar.loanCar.company_id),
            companyMessages: () => $rootScope.Company.getDefaultMessages(),
            customer       : () => self.LoanCar.loanCar.customer_id ? workshopAPI.get('/customer', self.LoanCar.loanCar.customer_id) : [],
            event          : () => self.LoanCar.loanCar.event_id ? workshopAPI.get('/event', self.LoanCar.loanCar.event_id) : [],
            invoice        : () => self.LoanCar.loanCar.invoice_id ? workshopAPI.get('/invoice', self.LoanCar.loanCar.invoice_id) : [],
            loan           : () => self.LoanCar.loanCar,
            loan_vehicle   : () =>  workshopAPI.get('/loan_vehicle', self.LoanCar.loanCar.loan_vehicle_id),
            serviceAdviser : () => self.LoanCar.loanCar.confirmed_by_before ? workshopAPI.get('/service_adviser', self.LoanCar.loanCar.confirmed_by_before) : [],
            states         : () => workshopAPI.get('/system/state_types/', null, 'states'),
            status         : () => workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes')
          }
        }).result;

      }
      else if (type == 'modifyLoan') {
        return $uibModal.open({
          templateUrl: CreateCarLoanHtml,
          windowClass: 'x-lg-modal',
          controller : 'ModifyLoanCarCtrl',
          resolve    : {
            status: [ function () {
              return workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes');
            }],
            customer: function () {
              return self.LoanCar.loanCar.customer_id ? workshopAPI.get('/customer', self.LoanCar.loanCar.customer_id) : [];
            },
            loan_car: function () {
              return self.LoanCar.loanCar;
            },
            loan_vehicle: function () {
              return self.LoanCar.loanCar.loan_vehicle_id ? workshopAPI.get('/loan_vehicle', self.LoanCar.loanCar.loan_vehicle_id) : [];
            },
            states: function () {
              return workshopAPI.get('/system/state_types/', null, 'states');
            },
            invoice: function () {
              return self.LoanCar.loanCar.invoice_id ? workshopAPI.get('/invoice', self.LoanCar.loanCar.invoice_id) : [];
            },
            event: function () {
              return self.LoanCar.loanCar.event_id ? workshopAPI.get('/event', self.LoanCar.loanCar.event_id) : [];
            },
            available_vehicles: function () {
              return workshopAPI.get('/loan_vehicle/available_vehicles_list', [self.LoanCar.loanCar.local_booking_start_time, self.LoanCar.loanCar.local_booking_end_time, self.LoanCar.loanCar.id]);
            }
          }
        }).result;

      }
      else if (type == 'returnLoan') {
        return $uibModal.open({
          templateUrl: ReturnLoanCarHtml,
          windowClass: 'x-lg-modal',
          controller : 'ReturnLoanCarCtrl',
          resolve    : {
            status: [ function () {
              return workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes');
            }],
            loan_vehicle: function () {
              return  workshopAPI.get('/loan_vehicle', self.LoanCar.loanCar.loan_vehicle_id);
            },
            states: function () {
              return workshopAPI.get('/system/state_types/', null, 'states');
            },
            loan: function () {
              return self.LoanCar.loanCar;
            },
            serviceAdviserBefore: function () {
              var service_adviser_id = self.LoanCar.loanCar.confirmed_by_before;
              return service_adviser_id ? workshopAPI.get('/service_adviser', self.LoanCar.loanCar.confirmed_by_before) : [];
            },
            serviceAdviserReturn: function () {
              var service_adviser_id = self.LoanCar.loanCar.confirmed_by_return;
              return service_adviser_id ? workshopAPI.get('/service_adviser', self.LoanCar.loanCar.confirmed_by_return) : [];
            },
            invoice: function () {
              return self.LoanCar.loanCar.invoice_id ? workshopAPI.get('/invoice', self.LoanCar.loanCar.invoice_id) : [];
            },
            event: function () {
              return self.LoanCar.loanCar.event_id ? workshopAPI.get('/event', self.LoanCar.loanCar.event_id) : [];
            },
            customer: function () {
              return self.LoanCar.loanCar.customer_id ? workshopAPI.get('/customer', self.LoanCar.loanCar.customer_id) : [];
            }
          }

        }).result;
      }
      else if (type == 'reviewLoan') {
        return $uibModal.open({
          templateUrl: ReviewLoanCarHtml,
          windowClass: 'x-lg-modal',
          controller : 'ReviewLoanCarCtrl',
          resolve    : {
            status: [ function () {
              return workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes');
            }],
            loan_vehicle: function () {
              return  workshopAPI.get('/loan_vehicle', self.LoanCar.loanCar.loan_vehicle_id);
            },
            states: function () {
              return workshopAPI.get('/system/state_types/', null, 'states');
            },
            loan: function () {
              return self.LoanCar.loanCar;
            },
            serviceAdviserBefore: function () {
              var service_adviser_id = self.LoanCar.loanCar.confirmed_by_before;
              return service_adviser_id ? workshopAPI.get('/service_adviser', self.LoanCar.loanCar.confirmed_by_before) : [];
            },
            serviceAdviserReturn: function () {
              var service_adviser_id = self.LoanCar.loanCar.confirmed_by_return;
              return service_adviser_id ? workshopAPI.get('/service_adviser', self.LoanCar.loanCar.confirmed_by_return) : [];
            },
            invoice: function () {
              return self.LoanCar.loanCar.invoice_id ? workshopAPI.get('/invoice', self.LoanCar.loanCar.invoice_id) : [];
            },
            event: function () {
              return self.LoanCar.loanCar.event_id ? workshopAPI.get('/event', self.LoanCar.loanCar.event_id) : [];
            },
            customer: function () {
              return self.LoanCar.loanCar.customer_id ? workshopAPI.get('/customer', self.LoanCar.loanCar.customer_id) : [];
            }
          }

        }).result;
      }
      else {
        return [];
      }
    }, function (err) {
      messages.error(err);
    });


  };

  LoanCarSchedule.prototype.loanStatus = function (status, type) {
    if (((type == 'startLoan' || type == 'modifyLoan') && status == 'P') || (type == 'returnLoan' && status == 'A') || (type == 'reviewLoan' && (status == 'A' || status == 'C'))) {
      return true;
    }
    else {
      return false;
    }
  };

  LoanCarSchedule.prototype.buildLoanDayTemplate = function (loan) {
    var self = this;


    return loan.loan_vehicle_id ? sprintf(
      '<div>' +
      '<div class="day-event" align="left"' +
        '>' +
        '<h4>%3$s</h4><br>' +
        '<strong>{{\'VEHICLE_SPACE.VEHICLE\' | translate}}:</strong><span> %2$s</span><br>' +
        '<strong>{{\'LOAN_CAR_SPACE.ESTIMATED_HOURS\' | translate}}:</strong><span> %4$s</span><br>' +
      '</div>'+
        '<div class="pull-right btn btn-success mgl-4" data-ng-click="goToLoan(\'%1$s\', \'%5$s\')" title="Start Loan" data-ng-show="LoanCarSchedule.loanStatus(\'%9$s\', \'%5$s\')">' +
            '<i class="fa fa-clock-o"></i>' +
        '</div>' +
        '<div class="pull-right btn btn-primary pointer mgl-4" data-ng-click="goToLoan(\'%1$s\', \'%6$s\')" title="Modify Loan" data-ng-show="LoanCarSchedule.loanStatus(\'%9$s\', \'%6$s\')">' +
            '<i class="fa fa-pencil"></i>' +
        '</div>' +
        '<div class="pull-right btn btn-primary mgl-4" data-ng-click="goToLoan(\'%1$s\', \'%7$s\')" title="Return Loan" data-ng-show="LoanCarSchedule.loanStatus(\'%9$s\', \'%7$s\')">' +
            '<i class="flaticon-car57"></i>' +
        '</div>' +
        '<div class="pull-right btn btn-warning pointer mgl-4" data-ng-click="goToLoan(\'%1$s\', \'%8$s\')" title="Review Loan" data-ng-show="LoanCarSchedule.loanStatus(\'%9$s\', \'%8$s\')">' +
            '<i class="fa fa-search"></i>' +
        '</div>' +
      '</div>'
      , loan.id, ((loan.vehicle_color ? (loan.vehicle_color + ' '): '') + (loan.make ? (loan.make + ' '): '') + (loan.model ? (loan.model + ' '): '') + (loan.plate_number || ''))
      , loan.customer_display_name, loan.estimated_hours, 'startLoan', 'modifyLoan', 'returnLoan', 'reviewLoan', loan.loan_status) : '';
  };
  //};

  //  buildEventLayoutClasses  used to dynamically write mechanic
  //                           classes to the head element configuring
  //                           their width and offset for week view.
  //  params: events           [Array] array of events.
  LoanCarSchedule.prototype.buildLoanLayoutClasses = function (loans) {
    var self = this;

    writeClass('diaryLoanWidth', sprintf('.diary-loan-width { width: %1$s !important }', getLoanWidth(self.view.intervalUnit, self.assignedLoanVehicles) + '%'));

    // build diary-mechanic class for each mechanic to give left to right offset.
    for (var i=0; i<self.assignedLoanVehicles.length; i++) {
      writeClass(
        sprintf('diaryVehicle%1$s', i+1),
        sprintf('.diary-vehicle-%1$s { left: %2$s !important; }',
        i+1,
        ((i * getLoanOffsetInterval(self.view.intervalUnit, self.assignedLoanVehicles)) + getInitialLoanOffset(self.view.intervalUnit, self.assignedLoanVehicles)) + '%'
        )
        );
      }
  };

  //  buildEvents     used to assign the built classes
  //                  to mechanic events as well as build
  //                  drop container events for day view.
  //  params: events  [Array] array of events.
  LoanCarSchedule.prototype.buildLoans = function (loans) {
    var self = this;

    var count;

    for (var i=0; i<loans.length; i++) {
      count = self.assignedLoanVehicles.indexOf(loans[i].title) + 1;

      loans[i].className += self.view.intervalUnit === 'week' ?
      ' diary-event-width diary-mechanic-' + count :
      '';

      loans[i].column = self.loanVehicleGroup.plate_numbers.indexOf(loans[i].plate_number);
    }

    if (self.view.intervalUnit === 'day') {

      // Build a background event for each column that will
      // be used for catching dropped unassigned jobs.
      for (var i=0; i<self.loanVehicleGroup.plate_numbers.length; i++) {
        loans.push({
          start     : moment(self.params.date).format('MMM D YYYY 00:00:01Z'),
          end       : moment(self.params.date).format('MMM D YYYY 23:59:59Z'),
          column    : i,
          rendering : 'background',
          identifier: 'column-' + i
        });
      }
    };

    return loans;
  };

  //  buildParams   used to build appropriate params for
  //                calls to retrieve events.
  LoanCarSchedule.prototype.buildParams = function () {
    var self = this;

    self.params = { date: self.start.format('YYYY-MM-DD'), loan_vehicle_ids: self.loanVehicleGroup.ids };

  };

  //  buildRouteAndReload used to build url with appropriate
  //                      params and reload the route.
  //  params: options     {Object} options object.
  LoanCarSchedule.prototype.buildRouteAndReload = function (options) {
    var self = this;

    $state.go('app.loan-car', {
      view                 : self.view.name,
      group_index          : self.loanVehicleGroupIndex,
      date                 : options.includeDate ? (options.date ? options.date : self.start.format('YYYY-MM-DD')) : undefined,
      loanVehicle          : options.loanVehicle,
      loanVehicleId        : options.loanVehicleId,
      viewSingleLoanVehicle: options.viewSingleLoanVehicle
    }, { reload: true });
  };

  //  checkUtcOffset  the fullCalendar plugin is set to a UTC timezone
  //                  for compatability with the fullCalendar-columns
  //                  plugin. Because of this, unless a date is specified
  //                  in the url the calendar could default to the wrong
  //                  date. This happens if the difference between the
  //                  user's current time and the closest adjacent day is
  //                  less than their local timezone's offset from UTC.
  //                  So to fix this we check for that specific case, and
  //                  add or subtract one day accordingly.
  LoanCarSchedule.prototype.checkUtcOffset = function (date) {
    var self = this;
    var timeDifference = 0;
    var hour = parseInt(moment().format('HH'));
    var utcOffset = parseInt(moment().format('Z').split(':')[0]);

    self.view.name = $stateParams.view || 'month';

    if (utcOffset < 0) {
      timeDifference = 24 - hour;

      if (timeDifference <= Math.abs(utcOffset)) {

        // subtract 1 day
        date = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
      }
    }
    else if (utcOffset > 0) {

      if (hour < utcOffset) {

        // add 1 day
        date = moment(date).add(1, 'days').format('YYYY-MM-DD');
      }
    }

    return date;
  };

  LoanCarSchedule.prototype.clearStyles = function () {
    var documentHead      = document.getElementsByTagName('head')[0];
    var fcViewContainer   = document.getElementById('fcViewContainer');
    var simplifiedDayView = document.getElementById('simplifiedDayView');

    if ( fcViewContainer ) {
      documentHead.removeChild(fcViewContainer);
    }

    if ( simplifiedDayView ) {
      documentHead.removeChild(simplifiedDayView);
    }
  };

  //  configureEvents   used to call for events. Then configures
  //                    event data for the current fullCalendar
  //                    view.
  //  params: start     Moment of calendar start date.
  //          end       Moment of calendar end date.
  //          timezone  Passed from fullCalendar.
  //          callback  Function passed from fullCalendar.
  LoanCarSchedule.prototype.configureLoans = function (start, end, timezone, callback) {
    var self = this;

    self.start = start;
    self.end = end;

    // getEvents() is being called multiple times on page
    // load with null parameters. Only initiate event
    // retrieval when params are defined.
    if (start) {
      self.buildParams();
      self.getLoans().then(function (loans) {

        // if month is current view, no need to define
        // classes for mechanic events.
        if (self.view.intervalUnit === 'month') {
          self.loans = loans;
          callback(self.loans);
        }
        else {
          self.assignLoanVehicles(loans);

          self.loans = self.buildLoans(loans);

          callback(self.loans);
        }
      });
    }
  };

  LoanCarSchedule.prototype.dayClick = function (date) {
    var self = this;

    if (self.view.name !== 'multiColAgendaDay') {
      self.view.name = 'multiColAgendaDay';
      self.buildRouteAndReload({ date: date.format('YYYY-MM-DD'), includeDate: true });
    }
  };

  //  getEvents  used to retrieve events for current calendar
  //             view.
  LoanCarSchedule.prototype.getLoans = function () {
    var self = this;

    if (!self.view.intervalUnit) {
      return $q.reject();
    }

    self.dayOfMonth = 0;

    return workshopAPI.post(self.loanRoutes[self.view.intervalUnit], self.params)
    .then(function (data) {
      data = self.loanConfiguration[self.view.intervalUnit](data);
      return $q.when(data);
    }, function (err) {
      return $q.reject(err);
    });
  };

  //  getEventTemplate  used to retrieve the appropriate event
  //                    template depending on the current view.
  //  params: event     {Object} current event object.
  LoanCarSchedule.prototype.getLoanTemplate = function (event) {
    var self = this;

    return self.buildLoanDayTemplate(event);
  };

  //  getNextGroup  used to get the next group of
  //                mechanic's data.
  LoanCarSchedule.prototype.getNextGroup = function () {
    var self = this;

    if (parseInt(self.loanVehicleGroupIndex) !== (self.loanVehicleGroups.length-1)) {
      self.loanVehicleGroupIndex++;
      self.loanVehicleGroup = self.loanVehicleGroups[self.loanVehicleGroupIndex];

      self.buildRouteAndReload({ includeDate: true });
    }
  };

  //  getPreviousGroup  used to get the previous group
  //                    of mechanic's data.
  LoanCarSchedule.prototype.getPreviousGroup = function () {
    var self = this;

    if (parseInt(self.loanVehicleGroupIndex) !== 0) {
      self.loanVehicleGroupIndex--;
      self.loanVehicleGroup = self.loanVehicleGroups[self.loanVehicleGroupIndex];

      self.buildRouteAndReload({ includeDate: true });
    }
  };

  //  updateScheduledLoan used to update a booked event when
  //                      the user drags it up/down left/right
  //                      on the calendar to adjust the time
  //                      or assigned loan vehicle.
  //  params: loan        {Object} the loan object.
  LoanCarSchedule.prototype.updateScheduledLoan = function (loan) {
    const self = this;

    // set url param to current date for better UX
    // when the route reloads.
    // TODO: confirm this updates the date stateParam without reloading
    // and persists any other query parameter values.
    $state.go('app.loan-car', {
      date: self.start.format('YYYY-MM-DD')
    }, { reload: false });

    // make sure the start and end properties use
    // the diaries start and end to avoid wrong
    // dates caused by the fullcalendar-columns
    // plugin.
    loan = {
      date            : self.start.format('YYYY-MM-DD'),
      id              : loan.id,
      is_default      : false,
      local_end_time  : `${self.start.format('YYYY-MM-DD')}T${moment(loan.end).format('HH:mm:ss')}${moment().format('Z')}`,
      local_start_time: `${self.start.format('YYYY-MM-DD')}T${moment(loan.start).format('HH:mm:ss')}${moment().format('Z')}`,
      loan_vehicle_id : self.loanVehicleGroup.ids[loan.column]
    };

    return workshopAPI.patch('/loan_car', loan, 'loan_car');
  };

  //  viewEvent      used to view an event in the day view
  //                 that the user selected from the month
  //                 view.
  //  params: event  {Object} the event object.
  LoanCarSchedule.prototype.viewLoan = function (loan) {
    var self = this;

    self.view.name = 'multiColAgendaDay';

    self.buildRouteAndReload({
      includeDate  : true,
      date         : loan.date,
      loanVehicle  : loan.plate_number,
      loanVehicleId: loan.id
    });
  };

  LoanCarSchedule.prototype.viewDate = function (date) {
    var self = this;

    self.view.name = 'multiColAgendaDay';

    self.buildRouteAndReload({
      includeDate: true,
      date       : date
    });
  };

  //  viewRender  used to check if view has been changed
  //              and if so, determine appropriate mechanic
  //              group size and date.
  LoanCarSchedule.prototype.viewRender = function (view) {
    var self = this;

    var date = view.start.format('YYYY-MM-DD');
    var includeDate = false;

    self.view = view;

    // user selected different view.
    if (self.view.name !== $stateParams.view) {

      // if switching to day view, group size is
      // reduced from 10 to 5.
      if (self.view.name === 'multiColAgendaDay') {
        date = self.view.start.format('YYYY-MM-DD');
        includeDate = true;

        self.loanVehicleGroupIndex *= 2;
      }

      // if switching from day view, group size is
      // doubled from 5 to 10.
      if ($stateParams.view === 'multiColAgendaDay') {
        self.loanVehicleGroupIndex = Math.floor(self.loanVehicleGroupIndex/2);
      }

      // if switching from day view to week view,
      // show week containing previously viewed day.
      if ($stateParams.view === 'multiColAgendaDay' && self.view.name === 'agendaWeek') {
        includeDate = true;
        date = moment(dateParsing.sunday(self.view.start.format('YYYY-MM-DD'))).add(1, 'days').format('YYYY-MM-DD');
      }

      self.buildRouteAndReload({ includeDate: includeDate, date: date });
    }

    // user clicked prev or next button.
    else if (self.view.name === 'multiColAgendaDay' && self.start && self.start !== date) {
      self.buildRouteAndReload({
        date                 : date,
        includeDate          : true,
        loanVehicle          : $stateParams.loanVehicle,
        loanVehicleId        : $stateParams.loanVehicleId,
        viewSingleLoanVehicle: $stateParams.viewSingleLoanVehicle
      });
    }
  };

  return LoanCarSchedule;
}