import { BlockUIModule }           from 'ng-block-ui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule }            from '@angular/common';
import { FormsModule }             from '@angular/forms';
import { Injector, NgModule }      from '@angular/core';
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModalConfig,
  NgbModalModule,
  NgbPopoverModule,
  NgbTooltipModule
}                                  from '@ng-bootstrap/ng-bootstrap';
import { NgPluralizeModule }       from 'ng-pluralize';
import { UIRouterUpgradeModule }   from '@uirouter/angular-hybrid';

import { ComponentsModule } from './components/components.module';
import { ControlsModule   } from './controls/controls.module';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule      } from './pipes/pipes.module';
import { ValidatorsModule } from './validators/validators.module';

@NgModule({
  exports: [
    // Angular
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,

    // Third Party
    BlockUIModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPopoverModule,
    NgbTooltipModule,
    UIRouterUpgradeModule,

     // Custom
    ComponentsModule,
    ControlsModule,
    DirectivesModule,
    PipesModule,
    ValidatorsModule
  ],
  imports: [
    // Angular
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,

    // Third Party
    BlockUIModule.forRoot(),
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgPluralizeModule,
    UIRouterUpgradeModule,

    // Custom
    ComponentsModule,
    ControlsModule,
    DirectivesModule,
    PipesModule,
    ValidatorsModule
  ],
  providers: [
    {
      deps      : [ '$injector' ],
      provide   : '$rootScope',
      useFactory: ( $injector : Injector ) => $injector.get('$rootScope')
    },
    {
      deps      : [ '$injector' ],
      provide   : '$translate',
      useFactory: ( $injector : Injector ) => $injector.get('$translate')
    },
    {
      deps      : [ '$injector' ],
      provide   : 'Company',
      useFactory: ( $injector : Injector ) => $injector.get('Company')
    },
    {
      deps      : [ '$injector' ],
      provide   : 'templatedWording',
      useFactory: ( $injector : Injector ) => $injector.get('templatedWording')
    }
  ]
})
export class SharedModule {
  constructor (
    private ngbModalConfig : NgbModalConfig
  ) {
    /**
     * global configuration options for
     * ngbModal.
     * TODO: Move configs to their own file(s)
     */
    this.ngbModalConfig.backdrop = 'static';
  }
}

