import { Injectable }                            from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { DateParserService } from '../../../core/services/date-parser.service';
import { DateService }       from './date.service';

@Injectable()
export class DateParserFormatter implements NgbDateParserFormatter {

  readonly DELIMITER = this.dateService.DELIMITERS.default.parser;

  FORMAT: string;

  constructor (
    private dateParserService: DateParserService,
    private dateService      : DateService
  ) {
    this.FORMAT = this.dateParserService.getFormat();
  }

  parse ( value : string ) : NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);

      return {
        day  : parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }

    return null;
  }

  format ( date : NgbDateStruct | null ) : string {
    return date ? this.FORMAT
    .replace(/dd/g, this.dateService.pad(date.day))
    .replace(/mm/g, this.dateService.pad(date.month))
    .replace(/yyyy/g, this.dateService.pad(date.year))
    : '';
  }
}
