import AdvisersSearchResultsHtml from '../advisers/templates/advisers.search.results.html';
import VehicleSearchResultsHtml  from '../vehicles/templates/vehicle.search.results.html';

export function StartLoanCarInvoiceCtrl (
  $uibModalInstance,
  $q,
  $scope,
  $state,
  $translate,
  $window,
  API,
  company,
  companyMessages,
  confirm,
  customer,
  event,
  invoice,
  loan,
  loan_vehicle,
  LoanCarInvoices,
  messages,
  serviceAdviser,
  sessionObjAPI,
  states,
  status,
  streamSearch,
  workshopAPI
) {

  $scope.vehicleSearchResultsTemplateUrl  = VehicleSearchResultsHtml;

  $scope.company         = company;
  $scope.companyMessages = companyMessages;
  $scope.customer        = customer;
  $scope.event           = event;
  $scope.invoice         = invoice;
  $scope.loan_car        = loan;
  $scope.loan_vehicle    = loan_vehicle;
  $scope.loanStatusTypes = status;
  $scope.states          = states;

  if ($scope.company.company_variable_fields.plate_number_name) {
    $scope.regoLabel = $scope.company.company_variable_fields.plate_number_name;
  }
  else {
    $scope.regoLabel = sessionObjAPI.countrySettings().rego_label;
  }

  $scope.LoanCarInvoices = new LoanCarInvoices($scope.loan_car, $scope.invoice);
  $scope.serviceAdviser = serviceAdviser;

  $scope.flipToTerms = function () {
    $scope.flipped = true;
    $scope.$emit('flipModal');
  };

  $scope.modifyLoan = function () {
    $scope.LoanCarInvoices.save().then(function () {
      $uibModalInstance.close();
    }).then(function () {
      $scope.LoanCarInvoices.modifyLoan();
    }, function (err) {
      return $q.reject(err);
    });
  };

  $scope.printAuthorisation = function () {
    $scope.LoanCarInvoices.save().then(function () {
      $window.open(`${API.url}/reports/jasper_loan_car_authorisation/${$scope.LoanCarInvoices.loanCar.id}.pdf`);
    });
  };

  $scope.returnToDetails = function () {
    $scope.flipped = false;
    $scope.$emit('flipModal');
  };

  $scope.startLoan = function () {
    $scope.LoanCarInvoices.save().then(function () {
      workshopAPI.get('/loan_car/start_loan_car', $scope.LoanCarInvoices.loanCar.id);
    }).then(function (loan) {
      $uibModalInstance.close();
      messages.show($translate.instant('LOAN_CAR_SPACE.LOAN_CAR_STARTED'), 'success');
    }, function (err) {
      return $q.reject(err);
    });
  };

  $scope.cancelLoanRequest = function () {
    confirm.generic($translate.instant('LOAN_CAR_SPACE.CANCEL_LOAN_CAR_REQUEST')).then(function () {
      return workshopAPI.get('/loan_car/cancel_loan_car_request', $scope.loan_car.id);
    }).then(function () {
      $uibModalInstance.close();
      messages.show($translate.instant('LOAN_CAR_SPACE.LOAN_CAR_REQUEST_CANCELLED'), 'success');
    }).catch(function (err) {
      messages.error(err);
    });
  };

  $scope.customerSearch = {
    list: [],
    selected: {
      id: customer ? $scope.customer.id : '',
      display_name: customer ? $scope.customer.display_name : ''
    },
    search: function (typed) {
      var deferred = $q.defer();
      streamSearch.search('customers', typed).then(function (data) {
        deferred.resolve($scope.customerSearch.list.concat(data));
      });
      return deferred.promise;
    },
    choose: function (customer) {
      this.selected = customer;
      $scope.customer = customer;
      $scope.loan_car.customer_id = customer.id;
      $scope.loan_car.contact_first_name = customer.first_name;
      $scope.loan_car.contact_last_name = customer.last_name;
      $scope.loan_car.contact_address1 = customer.address1;
      $scope.loan_car.contact_address2 = customer.address2;
      $scope.loan_car.contact_suburb = customer.suburb;
      $scope.loan_car.contact_state = customer.state;
      $scope.loan_car.contact_postcode = customer.postcode;
      $scope.loan_car.contact_phone = customer.phone;
      $scope.loan_car.contact_mobile = customer.mobile;
      $scope.loan_car.contact_email = customer.email;
    },
    format: function (customer) {
      return customer ? customer.display_name : '';
    }
  };

  $scope.loanVehicleSearch = {
    list: [],
    selected: {
      id: loan_vehicle ? $scope.loan_vehicle.id : '',
      plate_number: loan_vehicle ? $scope.loan_vehicle.plate_number : ''
    },
    search: function (typed) {
      var deferred = $q.defer();
      streamSearch.search('loan_vehicles', typed).then(function (data) {
        deferred.resolve($scope.loanVehicleSearch.list.concat(data));
      });
      return deferred.promise;
    },
    choose: function (loan_vehicle) {
      this.selected = loan_vehicle;
      $scope.loan_vehicle = loan_vehicle;
      $scope.loan_car.loan_vehicle_id = loan_vehicle.id;
    },
    format: function (loan_vehicle) {
      return loan_vehicle ? loan_vehicle.plate_number : '';
    }
  };

  $scope.serviceAdviserSearch = {
    templateUrl: AdvisersSearchResultsHtml,
    list: [],
    value: {
      id: serviceAdviser ? $scope.serviceAdviser.id : '',
      display_name: serviceAdviser ? $scope.serviceAdviser.display_name : '',
    },
    query: function (value) {
      return workshopAPI.get('/service_advisers/list', [ value, 0, '*' ]);
    },
    select: function (adviser) {
      $scope.serviceAdviser.id = adviser.id;
      $scope.serviceAdviser.display_name = adviser.display_name;
      $scope.loan_car.confirmed_by_before = $scope.serviceAdviser.id;
    },
    format: function (adviser) {
      return adviser ? adviser.display_name : '';
    }
  };

  $scope.delete = function () {
    confirm.generic($translate.instant('LOAN_CAR_SPACE.DELETE_LOAN_CAR')).then(function () {
      return workshopAPI.delete('/loan_car', $scope.loan_car.id);
    }).then(function () {
      $uibModalInstance.close();
      messages.show($translate.instant('LOAN_CAR_SPACE.LOAN_CAR_DELETED'), 'success');
      $state.go('app.loan-car');
    }).catch(function (err) {
      messages.error(err);
    });
  };

  $scope.selectTime = function (event, property) {
    var picker = $(event.currentTarget).datetimepicker({
      showMeridian: true,
      startView: 'day',
      initialDate: new Date()
    }).on('changeDate', function (ev) {
      // This datetime picker has a bug that returns UTC date instead of what was selected
      // so we need to convert it
      var date = new Date(ev.date.valueOf());
      $scope.LoanCarInvoices.loanCar[property] = moment($scope[property] = moment(new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes()))).format('hh:mm:ss A');
      if (!$scope.$$phase) {
        $scope.$apply();
      }
      $(picker).datetimepicker('hide');
    });
    $(picker).datetimepicker('show');
  };
}
