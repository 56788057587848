import CustomerSearchResultsHtml from '../customers/templates/customers.search.results.html';
import InvoiceSearchResultsHtml  from '../invoices/templates/invoice.search-results.html';
import VehicleSearchResultsHtml  from '../vehicles/templates/vehicle.search.results.html';

export function CreateLoanCarCtrl (
  $uibModalInstance,
  $q,
  $scope,
  $state,
  $stateParams,
  $translate,
  loan_vehicle,
  LoanCar,
  messages,
  sessionObjAPI,
  states,
  streamSearch,
  UKPostcode,
  workshopAPI
) {

  $scope.customerSearchResultsTemplateUrl = CustomerSearchResultsHtml;
  $scope.invoiceSearchResultsTemplateUrl  = InvoiceSearchResultsHtml;
  $scope.vehicleSearchResultsTemplateUrl  = VehicleSearchResultsHtml;

  $scope.loan_vehicle = loan_vehicle;
  $scope.states       = states;
  $scope.company      = sessionObjAPI.company();

  if ($scope.company.company_variable_fields.plate_number_name) {
    $scope.regoLabel = $scope.company.company_variable_fields.plate_number_name;
  }

  else {
    $scope.regoLabel = sessionObjAPI.countrySettings().rego_label;
  }

  $scope.loan_car = {
    loan_status: 'P',
    loan_vehicle_id: loan_vehicle ? loan_vehicle.id : '',
    start_date: $stateParams.date ? $stateParams.date : '',
    return_date: $stateParams.date ? $stateParams.date : '',
    start_time: $scope.company ? $scope.company.shop_opens : '',
    return_time: $scope.company ? $scope.company.shop_closes : '',
    loan_date: $stateParams.date ? $stateParams.date : ''
  };

  $scope.LoanCar    = new LoanCar($scope.loan_car);
  $scope.UKPostcode = new UKPostcode();

  if ($scope.LoanCar.invoice_id) {
    workshopAPI.get('/invoice', $scope.LoanCar.invoice_id).then(function (invoice) {
      $scope.invoice = invoice;
    });
  }

  if ($scope.LoanCar.event_id) {
    workshopAPI.get('/event', $scope.LoanCar.event_id).then(function (event) {
      $scope.event = event;
    });
  }

  $scope.customerSearch = {
    list: [],
    selected: {
      id: $scope.customer ? $scope.customer.id : '',
      display_name: $scope.customer ? $scope.customer.display_name : ''
    },
    search: function (typed) {
      var deferred = $q.defer();
      streamSearch.search('customers', typed).then(function (data) {
        deferred.resolve($scope.customerSearch.list.concat(data));
      });
      return deferred.promise;
    },
    choose: function (customer) {
      this.selected = customer;
      $scope.customer = customer;
      $scope.loan_car.customer_id = customer.id;
      $scope.loan_car.contact_first_name = customer.first_name;
      $scope.loan_car.contact_last_name = customer.last_name;
      $scope.loan_car.contact_address1 = customer.address1;
      $scope.loan_car.contact_address2 = customer.address2;
      $scope.loan_car.contact_suburb = customer.suburb;
      $scope.loan_car.contact_state = customer.state;
      $scope.loan_car.contact_postcode = customer.postcode;
      $scope.loan_car.contact_phone = customer.phone;
      $scope.loan_car.contact_mobile = customer.mobile;
      $scope.loan_car.contact_email = customer.email;
    },
    format: function (customer) {
      return customer ? customer.display_name : '';
    }
  };

  $scope.invoiceSearch = {
    list: [],
    selected: {
      id: '',
      display_name: ''
    },
    search: function (typed) {
      var deferred = $q.defer();
      streamSearch.search('invoices', typed).then(function (data) {
        deferred.resolve($scope.invoiceSearch.list.concat(data));
      });
      return deferred.promise;
    },
    choose: function (invoice) {
      this.selected = invoice;
      $scope.invoice = invoice;
      if ($scope.invoice.event_id) {
        workshopAPI.get('/event', $scope.invoice.event_id).then(function (event) {
          $scope.event = event;
        });
      }
      ;
      workshopAPI.get('/customer', $scope.invoice.customer_id).then(function (customer) {
        $scope.customer = customer;
        $scope.loan_car.customer_id = $scope.invoice.customer_id;
        $scope.loan_car.invoice_id = $scope.invoice.id;
        $scope.loan_car.event_id = $scope.invoice.event_id;
        $scope.loan_car.contact_first_name = customer.first_name;
        $scope.loan_car.contact_last_name = customer.last_name;
        $scope.loan_car.contact_address1 = customer.address1;
        $scope.loan_car.contact_address2 = customer.address2;
        $scope.loan_car.contact_suburb = customer.suburb;
        $scope.loan_car.contact_state = customer.state;
        $scope.loan_car.contact_postcode = customer.postcode;
        $scope.loan_car.contact_phone = customer.phone;
        $scope.loan_car.contact_mobile = customer.mobile;
        $scope.loan_car.contact_email = customer.email;
      });
    },
    format: function (invoice) {
      return invoice ? invoice.job_card_number : '';
    }
  };

  $scope.eventSearch = {
    list: [],
    selected: {
      id: '',
      display_name: ''
    },
    search: function (typed) {
      var deferred = $q.defer();
      streamSearch.search('events', typed).then(function (data) {
        deferred.resolve($scope.eventSearch.list.concat(data));
      });
      return deferred.promise;
    },
    choose: function (event) {
      this.selected = event;
      $scope.event = event;
      workshopAPI.get('/customer', $scope.event.customer_id).then(function (customer) {
        $scope.customer = customer;
        $scope.loan_car.customer_id = $scope.event.customer_id;
        $scope.loan_car.event_id = $scope.event.id;
        $scope.loan_car.contact_first_name = customer.first_name;
        $scope.loan_car.contact_last_name = customer.last_name;
        $scope.loan_car.contact_address1 = customer.address1;
        $scope.loan_car.contact_address2 = customer.address2;
        $scope.loan_car.contact_suburb = customer.suburb;
        $scope.loan_car.contact_state = customer.state;
        $scope.loan_car.contact_postcode = customer.postcode;
        $scope.loan_car.contact_phone = customer.phone;
        $scope.loan_car.contact_mobile = customer.mobile;
        $scope.loan_car.contact_email = customer.email;
      });
    },
    format: function (event) {
      return event ? event.event_number : '';
    }
  };

  $scope.loanVehicleSearch = {
    list: [],
    selected: {
      id: '',
      plate_number: ''
    },
    search: function (typed) {
      var deferred = $q.defer();
      streamSearch.search('loan_vehicles', typed).then(function (data) {
        deferred.resolve($scope.loanVehicleSearch.list.concat(data));
      });
      return deferred.promise;
    },
    choose: function (loan_vehicle) {
      this.selected = loan_vehicle;
      $scope.loan_vehicle = loan_vehicle;
      $scope.loan_car.loan_vehicle_id = loan_vehicle.id;
    },
    format: function (loan_vehicle) {
      return loan_vehicle ? loan_vehicle.plate_number : '';
    }
  };

  $scope.save = function () {
    $scope.LoanCar.save($scope.loan_car).then(function (loan) {
      $uibModalInstance.close();
      $state.reload();
      messages.show($translate.instant('LOAN_CAR_SPACE.LOAN_CAR_BOOKING_CREATED'), 'success');
    }).catch(function (err) {
      messages.error(err);
    });
  };

  $scope.searchUKPostcode = function () {
    $scope.UKPostcode.search($scope.loan_car.contact_postcode)
    .then(data => {
      $scope.loan_car.contact_address1 = data.Premises;
      $scope.loan_car.contact_address2 = data.Street;
      $scope.loan_car.contact_suburb   = data.PostTown;
      $scope.loan_car.contact_postcode = data.Postcode;

      messages.show($translate.instant('CUSTOMER_SPACE.CUSTOMER_ADDRESS_UPDATED'), 'success');
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.selectTime = function (event, property) {
    var picker = $(event.currentTarget).datetimepicker({
      showMeridian: true,
      startView: 'day',
      initialDate: new Date()
    }).on('changeDate', function (ev) {
      // This datetime picker has a bug that returns UTC date instead of what was selected
      // so we need to convert it
      var date = new Date(ev.date.valueOf());
      $scope.LoanCar.loanCar[property] = moment($scope[property] = moment(new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes()))).format('hh:mm:ss A');
      if (!$scope.$$phase) {
        $scope.$apply();
      }
      $(picker).datetimepicker('hide');
    });
    $(picker).datetimepicker('show');
  };
}
