import { Injectable } from '@angular/core';
import { UrlService } from '@uirouter/angularjs';

import { API }      from '../../api/api.constant.ajs';
import { ApiModel } from '../../api/models/api.model';

@Injectable({
  providedIn: 'root'
})
export class DomainParserService {
  _API     : ApiModel = API;

  constructor(
    private $urlService : UrlService
  ) {}

  getHostname () : string {
    const hostname  = this.$urlService.config.host();
    const subdomain = new RegExp(`${ this._API.subdomain }\.`, 'g');

    return this.isHttps()
      ? hostname.replace(subdomain, '')
      : hostname;
  }

  isBeta () : boolean {
    return this.$urlService.config.host().indexOf('beta') >= 0;
  }

  isCountry ( country : string ) : boolean {
    const domain = this.$urlService.config.host();

    return API.domains[country].indexOf(domain) > -1;
  }

  isHttps () : boolean {
    return this.$urlService.config.protocol() === 'https';
  }

  isInternetExplorer () : boolean {
    const ua = window.navigator.userAgent;

    return ua.indexOf('MSIE ') > 0
      || ua.indexOf('Trident/') > 0
      || ua.indexOf('Edge/') > 0;
  }
}
