import { Inject, Injectable }                 from '@angular/core';
import { BlockUIService as NgBlockUIService } from 'ng-block-ui';

@Injectable({
  providedIn: 'root'
})
export class BlockUIService {
  constructor (
    private blockUIService   : NgBlockUIService,
    @Inject('blockUI')
    private blockUIServiceAjs: ng.blockUI.BlockUIService
  ) {}

  unblock ( instance ?: string ) : void {
    if ( instance ) {
      this.blockUIServiceAjs.instances.get(instance).stop();

      this.blockUIService.stop(instance);
    }
    else {
      this.blockUIService.resetGlobal();

      /**
       * The typings for angular-block-ui are wrong and do
       * not treat the instances as an array. So, in order
       * to cast it from a custom type to a primitive type
       * we need to first set it to unknown.
       *
       * REFERENCE: WAU-272
      */
      ( this.blockUIServiceAjs.instances as unknown as Array<any> )
      .forEach(instance => this.blockUIServiceAjs.instances.get(instance._id).stop());
    }
  }
}