import { Injectable } from '@angular/core';

import { SessionServiceAjs } from './session.service.ajs';

@Injectable({
  providedIn: 'root'
})
export class ValidationsService {
  constructor (
    private sessionService : SessionServiceAjs
  ) {}

  private _getMobilePattern () : string {
    const validation = this.sessionService.countryValidation();

    if (validation.mobile && validation.mobile.validation) {
      return validation.mobile.validation;
    }
    else if (validation.phone && validation.phone.validation) {
      return validation.phone.validation;
    }
    else {
      return '^\\d+$';
    }
  }

  private _getPhonePattern () : string {
    const validation = this.sessionService.countryValidation();

    if (validation.phone && validation.phone.validation) {
      return validation.phone.validation;
    }
    else {
      return '^\\d+$';
    }
  }

  isPhoneNumberValid ( value : string, isMobile = false ) : boolean {
    const regex = isMobile
      ? this._getMobilePattern()
      : this._getPhonePattern();

    return value
      ? value.match(regex) !== null
      : true;
  }
}
