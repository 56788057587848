import { ApiServiceAjs }     from '../api/api.service.ajs';
import { BigDecimalService } from '../core/services/big-decimal.service';
import { InvoiceModel }      from '../invoices/models/invoice';
import { StripeService }     from '../stripe/stripe.service.ajs';

export class WorkshopPayService {
  $q           : ng.IQService;
  $translate   : ng.translate.ITranslateService;
  $uibModal    : ng.ui.bootstrap.IModalService;
  api          : ApiServiceAjs
  bigDecimalApi: BigDecimalService;
  companyApi   : any;
  stripeApi    : StripeService;

  constructor (
    $q           : ng.IQService,
    $translate   : ng.translate.ITranslateService,
    $uibModal    : ng.ui.bootstrap.IModalService,
    bigDecimalApi: BigDecimalService,
    Company      : any,
    stripeApi    : StripeService,
    workshopAPI  : ApiServiceAjs
  ) {
    this.$q            = $q;
    this.$translate    = $translate;
    this.$uibModal     = $uibModal;
    this.api           = workshopAPI;
    this.bigDecimalApi = bigDecimalApi;
    this.companyApi    = new Company();
    this.stripeApi     = stripeApi;
  }

  checkout ( invoice : InvoiceModel, token ?: string ) : ng.IPromise<stripe.paymentIntents.PaymentIntent | string> {
    return this.stripeApi.checkout(invoice, token);
  }

  isPaymentRequestAvailable ( invoice : InvoiceModel, isSale : boolean ) : boolean {
    const balance    = this.bigDecimalApi.get(invoice.balance_due, 2);
    const deposits   = this.bigDecimalApi.get(invoice.deposits_total, 2);
    const total      = this.bigDecimalApi.get(invoice.total, 2);
    // const invoiceApi = new this.Invoice(invoice);

    /**
     * TODO
     * the invoice api should be used here to check
     * the type and status. However, the invoice service
     * is still in the app folder. Until it is moved to
     * app-lite, it cannot be used here because this service
     * is also used on the workshopPay app which inherits app-lite.
     * So for now we put static checks.
     */
    return this.companyApi.hasIntegration('stripe')
      && !isSale
      && invoice.invoice_type !== 'C' //!invoiceApi.type('credit')
      && invoice.invoice_type !== 'Q' //!invoiceApi.type('quote')
      && invoice.invoice_status === 'P' //invoiceApi.status('processed')
      && balance.isPositive()
      && balance.equals(total)
      && deposits.isZero();
  }

  overrideCompanyInstance ( companyApi : any ) : void {
    this.companyApi = companyApi;
  }
}
