import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  open ( url : string, target = '_blank') : ng.IPromise<boolean> {
    return new Promise(resolve => {
      window.open(url, target);

      document.addEventListener('visibilitychange', () => this.resolveVisibility(resolve));
    });
  }

  private resolveVisibility ( resolve : any ) : void {
    if ( document.visibilityState === 'visible' ) {
      resolve(true);

      document.removeEventListener('visibilitychange', this.resolveVisibility);
    }
  }
}
