import { NgModule } from '@angular/core';

import { ServicesModule } from './services/services.module';

@NgModule({
  imports: [
    ServicesModule
  ]
})
export class CoreModule {}
