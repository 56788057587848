import { Injector, NgModule } from '@angular/core';

import { InvoiceContactStatusComponent } from './contact-status/invoice.contact-status.component';
import { InvoiceReferenceComponent }     from './invoice.reference.component';
import { InvoiceService }                from './invoice.service';
import { InvoiceSmsComponent }           from './invoice.sms.component';
import { InvoiceStatusPipe }             from './invoice.status.pipe';
import { InvoiceTypePipe }               from './invoice.type.pipe';
import { LinkedInvoicesComponent }       from './linked-invoices.component';
import { SharedModule }                  from '../shared/shared.module';
import { TableModule }                   from '../table/table.module';

@NgModule({
  declarations: [
    InvoiceContactStatusComponent,
    InvoiceReferenceComponent,
    InvoiceSmsComponent,
    InvoiceStatusPipe,
    InvoiceTypePipe,
    LinkedInvoicesComponent
  ],
  entryComponents: [
    InvoiceContactStatusComponent,
    InvoiceReferenceComponent,
    InvoiceSmsComponent,
    LinkedInvoicesComponent
  ],
  exports: [
    InvoiceContactStatusComponent,
    InvoiceSmsComponent,
    LinkedInvoicesComponent
  ],
  imports: [
    SharedModule,
    TableModule
  ],
  providers: [
    InvoiceService,
    InvoiceStatusPipe,
    InvoiceTypePipe,

    // Pipes must also be provided via string
    // if they are going to be used in a table
    // column. This allows the injector to be
    // able to load a pipe dynamically in the
    // TableCellComponent.
    {
      provide : 'invoiceStatus',
      useClass: InvoiceStatusPipe
    },
    {
      provide : 'invoiceType',
      useClass: InvoiceTypePipe
    },
    {
      deps      : [ '$injector' ],
      provide   : 'Invoice',
      useFactory: ( $injector : Injector ) => $injector.get('Invoice')
    }
  ]
})
export class InvoicesModule {}