import { Injectable } from '@angular/core';
import { NgbModal }   from '@ng-bootstrap/ng-bootstrap';

import { InvoiceModel }        from './models/invoice';
import { InvoiceSmsComponent } from './invoice.sms.component';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  constructor (
    private modalService: NgbModal
  ) {}

  sendSms ( invoice : InvoiceModel, sms : any ) : ng.IPromise<any> {
    const modal = this.modalService.open(InvoiceSmsComponent);

    modal.componentInstance.invoice = invoice;
    modal.componentInstance.sms     = sms;

    return modal.result;
  }
}
