import 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';

import { BigDecimalService }    from './big-decimal.service';
import { BlockUIService }       from './block-ui.service';
import { CurrencyService }      from './currency.service';
import { DateParserService }    from './date-parser.service';
import { DomainParserService }  from './domain.parser.service';
import { InheritorServiceAjs }  from './inheritor.service.ajs';
import { JsonService }          from './json.service';
import { PruneService }         from './prune.service';
import { WindowService }        from './window.service';

angular.module('app-lite.core.services', [])
  .service('bigDecimalApi',   downgradeInjectable(BigDecimalService))
  .service('blockApi',        downgradeInjectable(BlockUIService))
  .service('currencyApi',     downgradeInjectable(CurrencyService))
  .service('dateParsing',     downgradeInjectable(DateParserService))
  .service('domainParserApi', downgradeInjectable(DomainParserService))
  .service('inheritorApi',    InheritorServiceAjs)
  .service('jsonApi',         downgradeInjectable(JsonService))
  .service('pruneApi',        downgradeInjectable(PruneService))
  .service('windowApi',       downgradeInjectable(WindowService));