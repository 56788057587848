import { NgModule } from '@angular/core';

import { apiServiceProvider }  from './api.provider.ajs';
import { ApiService }          from './api.service';
import { CacheService }        from './cache.service';
import { StreamSearchService } from './stream-search.service';

@NgModule({
  providers: [
    // AJS upgraded service
    apiServiceProvider,

    ApiService,
    CacheService,
    StreamSearchService
  ]
})
export class ApiModule {}
