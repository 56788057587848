import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'archived'
})
export class ArchivedPipe implements PipeTransform {
  constructor (
    @Inject('$translate')
    private $translate: ng.translate.ITranslateService
  ) {}

  transform ( isArchived : boolean, options : any = { html: true } ) : string {
    return isArchived
    ? options.html
      ? `<span class="badge bg-danger">
          ${ this.$translate.instant('GENERAL_SPACE.SWITCH.ARCHIVED') }
        </span>`
      : this.$translate.instant('GENERAL_SPACE.SWITCH.ARCHIVED')
    : '';
  }
}
