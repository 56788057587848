import angular from 'angular';

import { ApiModel }          from './models/api.model';
import { ApiOptionsModel }   from './models/api.options';
import { CacheService }      from './cache.service';
import { ModalService }      from '../modals/modal.service.ajs';
import { PruneService }      from '../core/services/prune.service';
import { SessionServiceAjs } from '../sessions/session.service.ajs';

export class ApiServiceAjs {
  // used to prevent multiple alerts
  // from displaying simultaneously.
  isAlerted = false;

  constructor (
    private $http        : ng.IHttpService,
    private $q           : ng.IQService,
    private $rootScope   : any,
    private $timeout     : ng.ITimeoutService,
    private $translate   : ng.translate.ITranslateService,
    private $window      : ng.IWindowService,
    public API           : ApiModel,
    private cacheApi     : CacheService,
    private modalApi     : ModalService,
    private pruneApi     : PruneService,
    private sessionObjAPI: SessionServiceAjs
  ) {}

  private _isParamEmpty ( param : any ) : boolean {
    return param === undefined
      || param === null
      || param === '';
  }

  /**
   * a simple function that checks if the data
   * is an object, if it is, we stringify it and return it.
   * Used to transform requests that have been modified by outside functions.
   */
  private _stringify ( data : any ) : string {
    return angular.isObject(data) ? angular.toJson(data) : data;
  }

  private _validateSession (error) {
    let message = 'You have been logged out.';

    if ( !this.isAlerted && this.sessionObjAPI.isSessionError(error) ) {
      this.isAlerted = true;

      if (this.sessionObjAPI.isSessionExpired(error)) {
        message = this.$translate.instant('JS_SPACE.MESSAGES.SESSION.EXPIRED');
      }

      else if (this.sessionObjAPI.isSessionDeactivated(error)) {
        message = this.$translate.instant('JS_SPACE.MESSAGES.SESSION.DEACTIVATED');
      }

      this.sessionObjAPI.clear();
      this.$window.location.assign('login.html');

      this.modalApi
        .alert({
          message,
          title: 'Session Error'
        })
        .finally(() => this.isAlerted = false);
    }
  }

  delete ( url : string, id ?: string ) : ng.IPromise<any> {
    if (id) {
      url = url + '/' + this.encode(id);
    }

    return this.$timeout(() => {
      return this.$http.delete(encodeURI(`${this.API.url}${ url.charAt(0) === '/' ? '': '/' }${url}`), { withCredentials: true })
      .then(response => response.data)
      .catch(err => {
        this._validateSession(err);

        return this.$q.reject(err.data);
      });
    });
  }

  encode ( param : any, encodeFor = 'rails' ) : string {
    const encodings = {
      base: [
        // periods
        {
          character: /\./g,
          encoding : '%2E'
        },
        // hash signs
        {
          character: /#/g,
          encoding : '%23'
        },
        // forward slashes
        {
          character: /\//g,
          encoding : '%2F'
        },
        // question marks
        {
          character: /\?/g,
          encoding : '%3F'
        },
        // semi colons
        {
          character: /;/g,
          encoding : '%3B'
        },
      ],
      get: [
        // percentage sign
        {
          character: /%/g,
          encoding : '%25'
        }
      ],
      hsy: [
        // equals
        {
          character: /=/g,
          encoding : '%3D'
        },
        // ampersand
        {
          character: /&/g,
          encoding : '%26'
        },
        // colon
        {
          character: /:/g,
          encoding : '%3A'
        }
      ],
      rails: []
    };

    const replaces = encodings.base.concat(encodings[ encodeFor ]);

    for ( let i = 0; i < replaces.length; i++ ) {
      param = param.replace(replaces[ i ].character, replaces[ i ].encoding);
    }

    return param;
  }

  get ( url : string, params ?: any, cacheObj ?: string, config ?: any ) : ng.IPromise<any> {
    const cached = this.cacheApi.get(cacheObj);

    config = angular.isObject(config) ? config: {};

    if (params || params === false || params === 0) {
      if ( !angular.isArray(params) ) {
        params = [params];
      }

      for (var i = 0; i < params.length; i++) {
        if (!this._isParamEmpty(params[i])) {
          url = url + '/' + this.encode( params[i].toString(), 'get' );
        }
      };
    }

    if (cached) {
      return this.$q.when(cached);
    }

    this.$rootScope.resolving = true;

    if (!config.skipEncoding) {
      url = encodeURI(url);
    }

    config.withCredentials = true;

    return this.$timeout(() => {
      return this.$http.get(`${this.API.url}${ url.charAt(0) === '/' ? '': '/' }${url}`, config)
      .then(response => {
        if (cacheObj) {
          this.cacheApi.put(cacheObj, response.data);
        }

        return response.data;
      })
      .catch(err => {
        this._validateSession(err);

        return this.$q.reject(err.data);
      })
      .finally(() => {
        this.$rootScope.resolving = false;
      });
    });
  }

  patch ( url : string, data : any, name ?: string, cleanup ?: string, settings ?: any ) : ng.IPromise<any> {
    settings = angular.extend({
      data           : {},
      method         : 'PATCH',
      url            : `${this.API.url}${ url.charAt(0) === '/' ? '': '/' }${url}`,
      withCredentials: true
    }, settings || {});

    this.$rootScope.resolving = true;

    if (name) {
      settings.data[name] = data;
    }

    // If the parameters need to be flat
    else {
      settings.data = data || {};
    }

    if (settings.transformRequest) {
      settings.transformRequest = [settings.transformRequest, this._stringify];
    }

    if (cleanup) {
      settings.data[name][cleanup] = this.pruneApi.removeUntouched(settings.data[name][cleanup]);
    }

    return this.$timeout(() => {
      return this.$http(settings)
      .then(result => settings.verbose ? result : result.data)
      .catch(err => {
        this._validateSession(err);

        return this.$q.reject(settings.verbose ? err : err.data);
      })
      .finally(() => {
        this.$rootScope.resolving = false;
      });
    });
  }

  post (url : string, data ?: any, name ?: string, cleanup ?: string, settings ?: any) : ng.IPromise<any> {
    settings = angular.extend({
      data           : {},
      method         : 'POST',
      url            : `${this.API.url}${ url.charAt(0) === '/' ? '': '/' }${url}`,
      withCredentials: true
    }, settings || {});

    this.$rootScope.resolving = true;

    // If the parameters need to be nested from the session id
    if (name) {
      settings.data[name] = data;

      if (cleanup && angular.isString(cleanup)) {
        settings.data[name][cleanup] = this.pruneApi.removeUntouched(settings.data[name][cleanup]);
      }
      else if (cleanup) {
        settings.data[name] = this.pruneApi.removeUntouched(settings.data[name]);
      }
    }

    // If the parameters need to be flat
    else {
      settings.data = data;
    }

    if (settings.transformRequest) {
      settings.transformRequest = [settings.transformRequest, this._stringify];
    }

    return this.$timeout(() => {
      return this.$http(settings)
      .then(result => settings.verbose ? result : result.data)
      .catch(err => {
        this._validateSession(err);

        return this.$q.reject(settings.verbose ? err : err.data);
      })
      .finally(() => {
        this.$rootScope.resolving = false;
      });
    });
  }

  /**
   * The return type needs to be any because it can be either
   * a promise or an object containing the promise and the stream.
   * This is for certain places where the stream needs to be accessed
   * directly.
   *
   * REFERENCE: WAU-282
   */
  stream ( url : string, params ?: any, options ?: ApiOptionsModel ) : any {
    const deferred = this.$q.defer();

    if (params && !angular.isArray(params)) {
      params = [params];
    }
    else if (params) {
      for (var i = 0; i < params.length; i++) {
        url = url + '/' + this.encode( params[i].toString() );
      };
    }

    const stream : EventSource = new EventSource(encodeURI(`${this.API.url}${ url.charAt(0) === '/' ? '': '/' }${url}`), { withCredentials: true });

    stream.onmessage = function (e) {
      const data = JSON.parse(e.data);

      if (!data.length) {
        this.close();

        deferred.resolve([]);
      }
      else {
        deferred.resolve(data);
      }
    };

    stream.onerror = function () {
      this.close();

      deferred.resolve([]);
    };

    if (options?.verbose) {
      return {
        promise: deferred.promise,
        stream
      };
    }

    return deferred.promise;
  }
}