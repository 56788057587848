import '../../../vendor/bootstrap-datepicker/bootstrap-datepicker.min';

export function CoreReportDateDirective (dateParsing) {
  var regexObj = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

  return {
    require: 'ngModel',
    scope  : {
      jasper: '@'
    },
    link: function(scope, element, attrs, ctrl) {
      // find the datepicker button and initialize it first
      if (element.parent().find('.date').length) {
        element.parent().find('.date').datepicker({
          format   : dateParsing.getFormat(),
          autoclose: true,
          todayBtn : 'linked'
        }).on('changeDate', function(e){
          var date = e.date.getDate();
          var month = e.date.getMonth() + 1;
          var year = e.date.getFullYear();
          $(e.target.nextElementSibling).val(dateParsing.getFormat().replace('dd', date).replace('mm', month).replace('yyyy', year));
          $(e.target.nextElementSibling).trigger('input');
        });
      }

      ctrl.$parsers.push(function(value) {
        if (!value) {
          ctrl.$setValidity('$valid', true);
          ctrl.$viewValue = "";
          ctrl.$render();
          return "*";
        }
        if (value.match(regexObj)) {
          ctrl.$setValidity('$valid', true);
          ctrl.$viewValue = value;
          ctrl.$render();
          if (!dateParsing.getFormat() || dateParsing.getFormat() == 'dd/mm/yyyy') {
            value = value.replace(regexObj, '$2/$1/$3');
          }
          var fullDate = new Date(value);
          var date = fullDate.getDate();
          var month = fullDate.getMonth() + 1;
          var year = fullDate.getFullYear();
          return scope.jasper ? `${month}-${date}-${year}`: `${date}-${month}-${year}`;
        }
        else {
          ctrl.$setValidity('$valid', false);
          return '';
        }
      });

      ctrl.$formatters.push(function(value) {
        var regexObj = /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/;
        if (!value) {
          return '';
        }

        value = value.replace(/-/g, '/');
        if (value.match(regexObj)) {
          if (dateParsing.getFormat() == 'dd/mm/yyyy') {
            return value.replace(regexObj, '$3/$2/$1');
          }
          else {
            return value.replace(regexObj, '$2/$3/$1');
          }
        }
        else {
          return '';
        }

      });
    }
  };
}