export function CoreIntegrationFormDirective ($timeout, jsonApi) {
  return {
    restrict: 'E',
    replace: true,
    template: `
      <form style="display:none" name="repco" action="{{ form.url }}" method="POST">
        <div ng-repeat="field in form.fields">
          <input type="text" name="{{ field.name }}" ng-model="field.value"/>
        </div>
      </form>`,
    scope: {
      api: '&'
    },
    link: function(scope, element) {
      var queryParameters = jsonApi.getQueryParameters();

      scope.api().then(function (data) {
        scope.form = data;

        for (const key in queryParameters) {
          scope.form.fields.push({
            name: key,
            value: unescape(queryParameters[key].replace(/[+]/g, ' '))
          });
        };

        $timeout(function () {
          element.submit();
        }, 1000);

      });

    }
  };
}