export class InheritorServiceAjs {
  constructor (
    private $controller : ng.IControllerService
  ) {}

  private _getChildPrototype ( parent ) {
    return Object.create(parent.prototype);
  }

  inherit ( child : any, parent : any ) {
    parent.call(child);
  }

  inheritCtrl ( ctrl : string, scope : ng.IScope ) {
    this.$controller(ctrl, { $scope: scope });
  }

  bootstrap ( child : any, parent : any ) {
    child.prototype = this._getChildPrototype(parent);

    Object.defineProperty(child.prototype, 'constructor', {
      enumerable: false, // so that it does not appear in 'for in' loop
      value     : child,
      writable  : true
    });

    for ( const property in parent ) {
      child[property] = parent[property];
    }

    return child;
  }
}
