import CustomerSearchResultsHtml from '../customers/templates/customers.search.results.html';
import VehicleSearchResultsHtml  from '../vehicles/templates/vehicle.search.results.html';

export function CreateLoanCarInvoiceCtrl (
  $uibModalInstance,
  $q,
  $scope,
  $translate,
  confirm,
  customer,
  event,
  invoice,
  loan_vehicle,
  LoanCarInvoices,
  messages,
  sessionObjAPI,
  states,
  streamSearch,
  UKPostcode
) {

  $scope.customerSearchResultsTemplateUrl = CustomerSearchResultsHtml;
  $scope.vehicleSearchResultsTemplateUrl  = VehicleSearchResultsHtml;

  $scope.loan_vehicle = loan_vehicle;
  $scope.states       = states;
  $scope.invoice      = invoice;
  $scope.company      = sessionObjAPI.company();
  $scope.customer     = customer;
  $scope.event        = event;

  if ($scope.company.company_variable_fields.plate_number_name) {
    $scope.regoLabel = $scope.company.company_variable_fields.plate_number_name;
  }

  else {
    $scope.regoLabel = sessionObjAPI.countrySettings().rego_label;
  }


  $scope.loan_car = {
    loan_status: 'P',
    customer_id: customer ? customer.id : '',
    loan_date: invoice ? invoice.post_date : '',
    loan_vehicle_id: loan_vehicle ? loan_vehicle.id : '',
    contact_first_name: customer ? ((customer.customer_type == 'I') ? customer.first_name : customer.contact_first) : '',
    contact_last_name: customer ? ((customer.customer_type == 'I') ? customer.last_name : customer.contact_last) : '',
    contact_address1: customer ? customer.address1 : '',
    contact_address2: customer ? customer.address2 : '',
    contact_suburb: customer ? customer.suburb : '',
    contact_state: customer ? customer.state : '',
    contact_postcode: customer ? customer.postcode : '',
    contact_phone: customer ? customer.phone : '',
    contact_mobile: customer ? customer.mobile : '',
    contact_email: customer ? customer.email : '',
    invoice_id: invoice ? invoice.id : '',
    event_id: invoice ? invoice.event_id : '',
    start_date: invoice ? invoice.post_date : '',
    return_date: invoice ? invoice.post_date : '',
    start_time: $scope.company ? $scope.company.shop_opens : '',
    return_time: $scope.company ? $scope.company.shop_closes : ''
  };

  $scope.LoanCarInvoices = new LoanCarInvoices($scope.loan_car, $scope.invoice, customer);
  $scope.UKPostcode      = new UKPostcode();

  $scope.customerSearch = {
    list: [],
    selected: {
      id: customer ? customer.id : '',
      display_name: customer ? customer.display_name : ''
    },
    search: function (typed) {
      var deferred = $q.defer();
      streamSearch.search('customers', typed).then(function (data) {
        deferred.resolve($scope.customerSearch.list.concat(data));
      });
      return deferred.promise;
    },
    choose: function (customer) {
      this.selected = customer;
      $scope.customer = customer;
      $scope.loan_car.customer_id = customer.id;
      $scope.loan_car.contact_first_name = customer.first_name;
      $scope.loan_car.contact_last_name = customer.last_name;
      $scope.loan_car.contact_address1 = customer.address1;
      $scope.loan_car.contact_address2 = customer.address2;
      $scope.loan_car.contact_suburb = customer.suburb;
      $scope.loan_car.contact_state = customer.state;
      $scope.loan_car.contact_postcode = customer.postcode;
      $scope.loan_car.contact_phone = customer.phone;
      $scope.loan_car.contact_mobile = customer.mobile;
      $scope.loan_car.contact_email = customer.email;
    },
    format: function (customer) {
      return customer ? customer.display_name : '';
    }
  };

  $scope.loanVehicleSearch = {
    list: [],
    selected: {
      id: '',
      plate_number: ''
    },
    search: function (typed) {
      var deferred = $q.defer();
      streamSearch.search('loan_vehicles', typed).then(function (data) {
        deferred.resolve($scope.loanVehicleSearch.list.concat(data));
      });
      return deferred.promise;
    },
    choose: function (loan_vehicle) {
      this.selected = loan_vehicle;
      $scope.loan_vehicle = loan_vehicle;
      $scope.loan_car.loan_vehicle_id = loan_vehicle.id;
    },
    format: function (loan_vehicle) {
      return loan_vehicle ? loan_vehicle.plate_number : '';
    }
  };

  $scope.save = function () {
    $scope.LoanCarInvoices.save($scope.loan_car).then(function (loan) {
      $scope.loan_car = loan;
      $uibModalInstance.close();
      messages.show($translate.instant('LOAN_CAR_SPACE.LOAN_CAR_BOOKING_CREATED'), 'success');
      return confirm.generic($translate.instant('LOAN_CAR_SPACE.LOAN_CAR_BOOKING_START'));
    }).then(function () {
      return $scope.LoanCarInvoices.startLoanAfterCreate($scope.loan_car);
    }).catch(function (err) {
      messages.error(err);
    });
  };

  $scope.searchUKPostcode = function () {
    $scope.UKPostcode.search($scope.loan_car.contact_postcode)
    .then(data => {
      $scope.loan_car.contact_address1 = data.Premises;
      $scope.loan_car.contact_address2 = data.Street;
      $scope.loan_car.contact_suburb   = data.PostTown;
      $scope.loan_car.contact_postcode = data.Postcode;

      messages.show($translate.instant('CUSTOMER_SPACE.CUSTOMER_ADDRESS_UPDATED'), 'success');
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.selectTime = function (event, property) {
    var picker = $(event.currentTarget).datetimepicker({
      showMeridian: true,
      startView: 'day',
      initialDate: new Date()
    }).on('changeDate', function (ev) {
      // This datetime picker has a bug that returns UTC date instead of what was selected
      // so we need to convert it
      var date = new Date(ev.date.valueOf());
      $scope.LoanCarInvoices.loanCar[property] = moment($scope[property] = moment(new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes()))).format('hh:mm:ss A');
      if (!$scope.$$phase) {
        $scope.$apply();
      }
      $(picker).datetimepicker('hide');
    });
    $(picker).datetimepicker('show');
  };
}
