export function ReportsLiteCtrl (
  $scope,
  $translate,
  $window,
  API,
  Company,
  dateParsing,
  sessionObjAPI
) {

  $scope.Company = new Company($scope.company);

  $scope.default_branch = $scope.Company.isMultiBranch() && !$scope.Company.isBranchOwner() ? $scope.company.id : '*';
  $scope.isKiwi         = $scope.Company.country('newZealand');
  $scope.labels         = $scope.Company.getLabels();
  $scope.loaded         = true;

  $scope.booking_listing = {
    begin_date: '*',
    end_date  : '*',
    branch    : $scope.default_branch,
    pdf () {
      $window.open(`${API.url}/reports/jasper_booking_listing_report/pdf/${this.begin_date}/${this.end_date}/${this.branch}.pdf`);
    },
    csv () {
      $window.open(`${API.url}/reports/jasper_booking_listing_report/csv/${this.begin_date}/${this.end_date}/${this.branch}`);
    }
  };

  $scope.customer_balances = {
    order_by       : "*",
    older_than_date: '*',
    summary        : false,
    branch         : $scope.default_branch,
    pdf () {
      $window.open(`${ API.url }/reports/customer_balances/pdf/${ workshopAPI.encode($scope.customer_balances.order_by) }/${ $scope.customer_balances.older_than_date }/${ this.summary }/${ $scope.customer_balances.branch }.pdf`);
    },
    csv () {
      $window.open(`${ API.url }/reports/customer_balances/csv/${ workshopAPI.encode($scope.customer_balances.order_by) }/${ $scope.customer_balances.older_than_date }/${ this.summary }/${ $scope.customer_balances.branch }`);
    }
  };

  $scope.follow_up = {
    begin_date: '*',
    end_date  : '*',
    branch    : $scope.default_branch,
    pdf () {
      $window.open(`${API.url}/reports/follow_up_invoices/pdf/${$scope.follow_up.begin_date}/${$scope.follow_up.end_date}/${$scope.follow_up.branch}.pdf`);
    },
    csv () {
      $window.open(`${API.url}/reports/follow_up_invoices/csv/${$scope.follow_up.begin_date}/${$scope.follow_up.end_date}/${$scope.follow_up.branch}`);
    }
  };

  $scope.mechanic_performance = {
    begin_date   : '*',
    end_date     : '*',
    summary      : false,
    report_type  : false,
    show_open    : true,
    internal_only: '*',
    branch       : $scope.default_branch,
    pdf () {
      $window.open(`${API.url}/reports/jasper_mechanic_performance_report/pdf/${$scope.mechanic_performance.begin_date}/${$scope.mechanic_performance.end_date}/${$scope.mechanic_performance.summary}/${$scope.mechanic_performance.report_type}/${$scope.mechanic_performance.show_open}/${$scope.mechanic_performance.internal_only}/${$scope.mechanic_performance.branch}.pdf`);
    },
    csv () {
      $window.open(`${API.url}/reports/jasper_mechanic_performance_report/csv/${$scope.mechanic_performance.begin_date}/${$scope.mechanic_performance.end_date}/${$scope.mechanic_performance.summary}/${$scope.mechanic_performance.report_type}/${$scope.mechanic_performance.show_open}/${$scope.mechanic_performance.internal_only}/${$scope.mechanic_performance.branch}.pdf`);
    }
  };

  $scope.outstanding_balances = {
    order_by: "*",
    branch  : $scope.default_branch,
    pdf () {
      $window.open(`${ API.url }/reports/jasper_outstanding_customer_balances/pdf/${ workshopAPI.encode($scope.customer_balances.order_by) }/${ $scope.outstanding_balances.branch }.pdf`);
    },
    csv () {
      $window.open(`${ API.url }/reports/jasper_outstanding_customer_balances/csv/${ workshopAPI.encode($scope.customer_balances.order_by) }/${ $scope.outstanding_balances.branch }`);
    }
  };

  $scope.rego_renewal_due = {
    begin_date: '*',
    end_date  : '*',
    branch    : $scope.default_branch,
    pdf () {
      $window.open(`${API.url}/reports/jasper_rego_renewal/pdf/${$scope.rego_renewal_due.begin_date}/${$scope.rego_renewal_due.end_date}/${$scope.rego_renewal_due.branch}.pdf`);
    },
    csv () {
      $window.open(`${API.url}/reports/jasper_rego_renewal/csv/${$scope.rego_renewal_due.begin_date}/${$scope.rego_renewal_due.end_date}/${$scope.rego_renewal_due.branch}`);
    }
  };

  $scope.sales_breakup = {
    begin_date   : '*',
    end_date     : '*',
    summary      : false,
    internal_only: '*',
    branch       : $scope.default_branch,
    pdf () {
      $window.open(`${API.url}/reports/jasper_sales_breakup_report/pdf/${$scope.sales_breakup.begin_date}/${$scope.sales_breakup.end_date}/${$scope.sales_breakup.summary}/${$scope.sales_breakup.internal_only}/${$scope.sales_breakup.branch}.pdf`);
    },
    csv () {
      $window.open(`${API.url}/reports/jasper_sales_breakup_report/csv/${$scope.sales_breakup.begin_date}/${$scope.sales_breakup.end_date}/${$scope.sales_breakup.summary}/${$scope.sales_breakup.internal_only}/${$scope.sales_breakup.branch}`);
    }
  };

  $scope.sales_report = {
    begin_date   : dateParsing.thisYearStart(),
    branch       : $scope.default_branch,
    customer_type: '*',
    end_date     : dateParsing.today(),
    internal_only: '*',
    limit_by_type: '*',
    sort_by      : 'C',
    pdf () {
      this.begin_date  = ( !this.begin_date || this.begin_date === '*' ) ? dateParsing.thisYearStart()  : this.begin_date;
      this.end_date    = ( !this.end_date   || this.end_date === '*' )   ? dateParsing.today()          : this.end_date;
      this.end_range   = this.end_range || 'zzzz';
      this.start_range = this.start_range || '0';

      $window.open(`${API.url}/reports/jasper_sales_report/pdf/${this.begin_date}/${this.end_date}/${this.sort_by}/${this.start_range}/${this.end_range}/${this.sort_range}/${this.limit_by_type}/${this.internal_only}/${this.branch}/${this.customer_type}.pdf`);
    },
    csv () {
      this.begin_date  = ( !this.begin_date || this.begin_date === '*' ) ? dateParsing.thisYearStart()  : this.begin_date;
      this.end_date    = ( !this.end_date   || this.end_date === '*' )   ? dateParsing.today()          : this.end_date;
      this.end_range   = this.end_range || 'zzzz';
      this.start_range = this.start_range || '0';

      $window.open(`${API.url}/reports/jasper_sales_report/csv/${this.begin_date}/${this.end_date}/${this.sort_by}/${this.start_range}/${this.end_range}/${this.sort_range}/${this.limit_by_type}/${this.internal_only}/${this.branch}/${this.customer_type}`);
    },
    customerType: [
      {
        code       : '*',
        description: 'All'
      },
      {
        code       : 'C',
        description: 'Cash'
      },
      {
        code       : 'A',
        description: 'Account'
      }
    ],
    sortBy: [
      {
        code       : 'C',
        description: $translate.instant('CUSTOMER_SPACE.CUSTOMER')
      }, {
        code       : 'P',
        description: $translate.instant('GENERAL_SPACE.FIELD.POST_DATE')
      }, {
        code       : 'R',
        description: $scope.labels.rego
      }, {
        code       : 'I',
        description: $translate.instant('GENERAL_SPACE.FIELD.INVOICE_TYPE')
      },
      ...($scope.Company.isBrand('nzaa')
        ? [
            {
              code       : 'M',
              description: $translate.instant('GENERAL_SPACE.FIELD.MEMBER_NON_MEMBER')
            }
          ]
        : []),
      ...($scope.Company.areServiceAdvisersEnabledOnReports()
        ? [
            {
              code       : 'S',
              description: $translate.instant('GENERAL_SPACE.FIELD.SERVICE_ADVISER')
            }
          ]
        : [])
    ],
    start_range: '',
    end_range  : '',
    sort_range : '*'
  };

  $scope.service_due = {
    begin_date: '*',
    end_date  : '*',
    branch    : $scope.default_branch,
    pdf () {
      $window.open(`${API.url}/reports/service_due/pdf/${$scope.service_due.begin_date}/${$scope.service_due.end_date}/${$scope.service_due.branch}.pdf`);
    },
    csv () {
      $window.open(`${API.url}/reports/service_due/csv/${$scope.service_due.begin_date}/${$scope.service_due.end_date}/${$scope.service_due.branch}.csv`);
    }
  };

  $scope.vehicle_sales = {
    begin_date: '*',
    end_date  : '*',
    order_by  : 'S',
    branch    : $scope.default_branch,
    pdf () {
      $window.open(`${API.url}/reports/jasper_vehicle_sales/pdf/${$scope.vehicle_sales.begin_date}/${$scope.vehicle_sales.end_date}/${$scope.vehicle_sales.order_by}/${$scope.vehicle_sales.branch}.pdf`);
    },
    csv () {
      $window.open(`${API.url}/reports/jasper_vehicle_sales/csv/${$scope.vehicle_sales.begin_date}/${$scope.vehicle_sales.end_date}/${$scope.vehicle_sales.order_by}/${$scope.vehicle_sales.branch}`);
    }
  };

  $scope.vendor_balances = {
    order_by       : "*",
    older_than_date: '*',
    branch         : $scope.default_branch,
    pdf () {
      $window.open(`${ API.url }/reports/vendor_balances/pdf/${ workshopAPI.encode($scope.vendor_balances.order_by) }/${ $scope.vendor_balances.older_than_date }/${ $scope.vendor_balances.branch }.pdf`);
    },
    csv () {
      $window.open(`${ API.url }/reports/vendor_balances/csv/${ workshopAPI.encode($scope.vendor_balances.order_by) }/${ $scope.vendor_balances.older_than_date }/${ $scope.vendor_balances.branch }`);
    }
  };

  $scope.wof_due = {
    begin_date: '*',
    end_date  : '*',
    branch    : $scope.default_branch,
    pdf () {
      $window.open(`${API.url}/reports/jasper_wof_due/pdf/${$scope.wof_due.begin_date}/${$scope.wof_due.end_date}/${$scope.wof_due.branch}.pdf`);
    },
    csv () {
      $window.open(`${API.url}/reports/jasper_wof_due/csv/${$scope.wof_due.begin_date}/${$scope.wof_due.end_date}/${$scope.wof_due.branch}`);
    }
  };

  $scope.isBoschPuppet = function() {
    const base = sessionObjAPI.base();

    return base && base.puppet_session && $scope.company.branding_group === 'bosch';
  };
}