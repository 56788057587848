import {
  Component,
  Inject,
  Input,
  OnInit
} from '@angular/core';

import { BlockUI, NgBlockUI }               from 'ng-block-ui';
import { NgbModal }                         from '@ng-bootstrap/ng-bootstrap';
import { UIRouter }                         from '@uirouter/angular';

import { CompanyIntegrationsModel }  from '../companies/models/company.integrations';
import { MessagesServiceAjs }        from '../messages/messages.service.ajs';
import { TillpaymentsService }       from './tillpayments.service';
import { TillpaymentsTerminalModel } from './models/tillpayments.terminal';
import { TillpaymentsActivatePosComponent } from './tillpayments.activate-pos.component';

@Component({
  selector   : 'wor-tillpayments-pos-settings',
  template: require('./tillpayments.pos-settings.component.html')
})
export class TillpaymentsPosSettingsComponent implements OnInit {
  @BlockUI('tillpaymentsPosblock') block: NgBlockUI;

  @Input() settings : CompanyIntegrationsModel;

  loading   : boolean;
  terminals : Array<TillpaymentsTerminalModel>;

  constructor (
    @Inject('$rootScope')
    public $rootScope           : any,
    @Inject('$translate')
    public $translate           : ng.translate.ITranslateService,
    private messagesService     : MessagesServiceAjs,
    private modalService        : NgbModal,
    private router              : UIRouter,
    private tillpaymentsService : TillpaymentsService
  ) {}

  activate () : void {
    const modal = this.modalService.open(TillpaymentsActivatePosComponent);

    modal.componentInstance.settings = this.settings;

    modal.result
    .then(( settings : CompanyIntegrationsModel ) => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATED', {
        name: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.TERMINAL_PAYMENTS')
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(() => this.router.stateService.reload());
  }

  deactivate () : void {
    this.tillpaymentsService.deactivatePos()
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.INTEGRATIONS.DEACTIVATED', {
        name: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.TERMINAL_PAYMENTS')
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  ngOnInit () : void {
    if ( this.tillpaymentsService.isPosReady(this.settings) ) {
      this.block.start();

      this.loading = true;

      this.tillpaymentsService.getTerminals()
      .then(terminals => {
        this.terminals = terminals;
      })
      .catch(err => {
        this.messagesService.error(err);
      })
      .finally(() => {
        this.block.stop();

        this.loading = false;
      });
    }
  }

  save () : void {
    this.block.start();

    this.tillpaymentsService.saveSettings(this.settings)
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.MESSAGES.SAVED.SETTINGS'), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    })
    .finally(() => {
      this.block.stop();
    });
  }
}
