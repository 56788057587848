import CustomerSearchResultsHtml from './templates/customers.search.results.html';

export function ChangeCustomerLiteCtrl (
  $scope,
  streamSearch
) {
  $scope.customerSearchResultsTemplateUrl = CustomerSearchResultsHtml;

  $scope.search = {
    list    : [],
    selected: {
      id          : '',
      display_name: ''
    },
    format: customer => customer ? customer.display_name: '',
    search: typed => streamSearch.search('customers', typed)
      .then(customers => $scope.search.list.concat(customers))
  };

  $scope.confirm = function () {
    $scope.$close($scope.search.selected);
  };
}