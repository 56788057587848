import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BigDecimalService {
  get ( number ?: string | number, scale ?: number, rounding = BigDecimal.ROUND_HALF_UP  ) : any {
    const convertedNumber = this.isNumber(number)
      ? new BigDecimal(number.toString())
      : BigDecimal.ZERO;

    return scale
      ? convertedNumber.setScale(scale, rounding)
      : convertedNumber;
  }

  isNumber ( number : any ) : boolean {
    return !( number === undefined || number === false || number === null || number === '' || isNaN(+number) );
  }

  round ( value : any, roundingVal ?: any ) : string {
    const twenty = this.get('20.00');

    value = this.get(value);

    return roundingVal
      ? value.add(this.get(roundingVal)).toString()
      : value
        .multiply(twenty)
        .setScale(0, BigDecimal.ROUND_HALF_UP) // Round to nearest integer
        .setScale(2, BigDecimal.ROUND_HALF_UP) // Set scale back to two decimal places
        .divide(twenty)
        .setScale(2, BigDecimal.ROUND_HALF_UP) // Ensure scale is at two decimal places
        .toString();
  }
}
