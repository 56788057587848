import { ApiServiceAjs }        from '../../app-lite/api/api.service.ajs';
import { QuickbooksServiceAjs } from './quickbooks.service.ajs';

import QuickbooksConnectionHtml from './quickbooks.connection.html';
import QuickbooksCountryHtml    from './quickbooks.country.html';
import QuickbooksSignupHtml     from './quickbooks.signup.html';

export function QuickbookStates (
  $stateProvider : ng.ui.IStateProvider
) {
  $stateProvider
  .state({
    controller : 'QuickbooksConnectionCtrl',
    name       : 'app.quickbooks-connection',
    templateUrl: QuickbooksConnectionHtml,
    url        : '/quickbooks-connection?disconnect&qbo_login&qbo_sso_token'
  })
  .state({
    controller : 'QuickbooksCountryCtrl',
    name       : 'app.quickbooks-country',
    templateUrl: QuickbooksCountryHtml,
    url        : '/quickbooks-country?disconnect&qbo_login&qbo_sso_token',
    resolve    : {
      countries: ( workshopAPI : ApiServiceAjs ) : ng.IPromise<any> => workshopAPI.get('/system/country_types', null, null, {
        bypass: true
      })
    }
  })
  .state({
    controller : 'QuickbooksSignupCtrl',
    name       : 'app.quickbooks-signup',
    templateUrl: QuickbooksSignupHtml,
    url        : '/quickbooks-signup/:country?&disconnect&qbo_login&qbo_sso_token',
    resolve    : {
      country  : ( $stateParams : ng.ui.IStateParamsService ) : string => $stateParams.country,
      countries: ( workshopAPI : ApiServiceAjs ) : ng.IPromise<any> => workshopAPI.get('/system/country_types', null, null, {
        bypass: true
      })
    }
  });
}