import { Injectable } from '@angular/core';

import { ApiOptionsModel }            from './models/api.options';
import { ApiServiceAjs }              from './api.service.ajs';
import { VerboseStreamResponseModel } from './models/stream.verbose-response';

@Injectable({
  providedIn: 'root'
})
export class StreamSearchService {
  constructor (
    private apiService: ApiServiceAjs
  ) {}

  search ( category : string, typed : string , options ?: ApiOptionsModel ) : ng.IPromise<any> | VerboseStreamResponseModel {
    return this.apiService.stream('searches', [ typed, category ], options);
  }
}