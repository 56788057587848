import CustomerSearchResultsHtml from '../customers/templates/customers.search.results.html';

export function VehicleTransferOwnerLiteCtrl (
  $scope,
  customer,
  messages,
  streamSearch,
  vehicle,
  workshopAPI
) {
  $scope.customerSearchResultsTemplateUrl = CustomerSearchResultsHtml;

  $scope.customer = customer;
  $scope.vehicle  = vehicle;

  $scope.customerSearch = {
    list    : [],
    selected: {
      display_name: '',
      id          : ''
    },
    choose: customer => $scope.customerSearch.selected = customer,
    format: customer => customer ? customer.display_name: '',
    search: typed    => streamSearch.search('customers', typed).then(data => $scope.customerSearch.list.concat(data))
  };

  $scope.confirm = function () {
    workshopAPI
      .get('vehicles/change_owner', [ $scope.vehicle.id, $scope.customerSearch.selected.id ])
      .then(vehicle => $scope.$close(vehicle))
      .catch(err => messages.error(err));
  };
};